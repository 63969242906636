type indexedDBType = {
    connect: (upgradeCallBack?: any) => Promise<IDBDatabase>,
    createTables: (db: any) => void,
    get: (
        database:IDBDatabase, 
        table: string, 
        key: string
    ) => Promise<any>,
    getAll: (
        database:IDBDatabase, 
        table: string, 
        condition?: (data: any) => boolean
    ) => Promise<any>,
    queryByDateRange: (database:IDBDatabase, table: string, indexField: string, dateRange: {
        from?: Date,
        to?: Date
    }) => Promise<any>,
    insert: (database:IDBDatabase, table: string, data: any, indexes?: { [key: string]: any }) => void,
    update: (database:IDBDatabase, table: string, data: any) => void,
    remove: (database:IDBDatabase, table: string, key: string) => Promise<any>,
}

const useIndexedDB  = (): indexedDBType =>  {
    const database = 'varilux';
    const version = 1;
    const tables = ['userStore', 'applicationStore', 'lensDemoStore', 'localAssetsStore', 'languageStore', 'variluxG9Store'];

    // Note: 1 index per table
    const tableIndexes: {
        [key: string]: {
            name: string,
            options: any
        }
    } = {
        userStore: { name: 'createdDate',  options: { unique:false} }
    }


    const connect = (upgradeCallBack?: any) => {
        const promise = new Promise<IDBDatabase>((res, rej) => {
            let DBOpenRequest = window.indexedDB.open(database, version);
            DBOpenRequest.addEventListener('success', (ev: any) => {
                res(ev.target.result);
            });
            DBOpenRequest.addEventListener('upgradeneeded', (ev: any) => {
                if(upgradeCallBack)upgradeCallBack(ev.target);

            });
            DBOpenRequest.addEventListener('error', (err: any) => {
                rej(err)
            });
        })
        return promise;
    }

    const createTables = (db: any) => {
        tables.forEach( t => {
            if(!db.result.objectStoreNames.contains(t)){
                const store = db.result.createObjectStore(t, {
                    keyPath: 'id'
                });    
                const indx = tableIndexes[t];
                if(indx){
                    store.createIndex(indx.name, indx.name, indx.options);
                }
            } else {
                const indx = tableIndexes[t];
                const objectStore = db.transaction.objectStore(t);
                if(indx && !objectStore.indexNames.contains(indx.name)){
                    objectStore.createIndex(indx.name, indx.name, { unique: false });
                }
            }
        })
    }

    const get = (database:IDBDatabase, table: string, key: string) => {
        const tx = database.transaction(table,'readonly');
        const store = tx.objectStore(table);
        const getRequest = store.get(key);

        return new Promise((resolved, reject) => {
            getRequest.onsuccess = (ev: any) => {
                resolved(ev.target.result);
            }
            getRequest.onerror = (err: any) => { 
                reject(err);
            }
        });        
    }

    const getAll = (database:IDBDatabase, table: string, condition?: (data: any) => boolean) => { 
        const tx = database.transaction(table,'readonly');
        const store = tx.objectStore(table);
        const request = store.openCursor();
        const all:any[] = [];
        return new Promise((resolved, reject) => {
            request.onsuccess = (ev: any) => {
                const cursor = request.result || ev.result;  
                if(!cursor)return;
                if( 
                    ( cursor.value && condition && condition(cursor.value)) ||
                    ( cursor.value && !condition )
                ){
                    all.push(cursor.value);                    
                }
                cursor.continue();
            }
            tx.oncomplete = () => {
                resolved(all);
            }
            request.onerror = (err: any) => { 
                reject(err);
            }
        });        
    }

    const insert = (database:IDBDatabase, table: string, data: any, indexes?: {
        [key: string]: any
    }) => {
      const tx = database.transaction(table,'readwrite');
      const store = tx.objectStore(table);
    
      let finalData = !indexes ? data : {
          ...data,
          ...indexes
      };

      const request = store.add(finalData);
      request.onsuccess = () => { }
      request.onerror = () => { }
    }

    const update = (database:IDBDatabase,table: string, data: any) => {
        const tx = database.transaction(table,'readwrite');
        const store = tx.objectStore(table);
        const request = store.put(data);
        request.onsuccess = () => { }
        request.onerror = () => { }
    }

    const remove = (database:IDBDatabase,table: string, key: string) => {
        const transaction = database.transaction([table], "readwrite");
        const request = transaction.objectStore(table).delete(key);

        return new Promise((resolved, reject) => {
            request.onsuccess = (ev: any) => {
                resolved(ev.target.result);
            }
            request.onerror = (err: any) => { 
                reject(err);
            }
        });
    }

    const queryByDateRange = (
            database:IDBDatabase, 
            table: string, 
            indexField: string, 
            dateRange: {
                from?: Date,
                to?: Date
            }
    ) => {
        const transaction = database.transaction([table],"readonly");
        const store = transaction.objectStore(table);
        if(store.indexNames.length === 0) return new Promise((res, rej) => rej('failed'));

        const index = store.index(indexField);
        let range: any;

        if(dateRange?.from && dateRange?.to ) {
            range = IDBKeyRange.bound(dateRange?.from, dateRange?.to);
        } else if(!dateRange?.from) {
            range = IDBKeyRange.upperBound(dateRange?.to);
        } else {
            range = IDBKeyRange.lowerBound(dateRange?.from);
        }
        
        let results: any[] = [];
        const promise = new Promise((resolved, reject) => {
            index.openCursor(range).onsuccess = (e: any) => {
                const cursor = e.target.result;
                if(cursor) {
                    results = [...results, cursor.value];
                    cursor.continue();
                } else {
                    resolved(results);
                }
            }
            index.openCursor(range).onerror = (error: any) => reject(error)
        })
                
        return promise;
    }

    return {
        connect,
        createTables,
        get,
        getAll,
        insert,
        update,
        remove,
        queryByDateRange
    }
}

export default useIndexedDB;