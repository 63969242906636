import useBEM from "../../utils/hooks/useBEM"

interface Props{
  text:string,
  click?: () => void,
  className?:string,
  selected?:boolean,
  gtmId?: string
}

const Champ:React.FC<Props> = ({
  click,
  text,
  selected = true,
  gtmId
    }) => {
  const [B,E] = useBEM('champ')

  const onClickHandler = () => {
    if(!click)return;
    click();
  }

  return (
    <div 
        id={`${selected ? `${gtmId}_unselected` : `${gtmId}_selected`}`} 
        className={B(`${selected ? 'selected' : ''}`)} 
        onClick={onClickHandler}
    >
      <span className={E('text')} id={`${selected ? `${gtmId}_unselected` : `${gtmId}_selected`}`} >
        {text}
      </span>
      <span className={E('oval')} id={`${selected ? `${gtmId}_unselected` : `${gtmId}_selected`}`} >
        {selected && 
          <div className={E('oval-selected')} id={`${selected ? `${gtmId}_unselected` : `${gtmId}_selected`}`} ></div>
        }
      </span>
    </div>
  )
}

export default Champ