import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/ui/Button";
import Dialogue from "../../../components/ui/Dialogue";
import prescriptionOptions from "../../../constants/variluxG9/prescription-options";
import { getApp } from "../../../store/slices/app.slice";
import variluxgG9Slice, {
  getVariluxG9,
} from "../../../store/slices/variluxG9.slice";
import PrescriptionProductTypeVarilux from "../../../types/prescription-product-varilux.type";
import useBEM from "../../../utils/hooks/useBEM";
import useTranslation from "../../../utils/hooks/useTranslation";
import useVariluxDisableButton from "../../../utils/hooks/useVariluxDisableButton";
import useVariluxMatrix from "../../../utils/hooks/useVariluxMatrix";
import Icon from "../../../components/ui/Icon";
import settings from "../../../constants/settings.constants";
import {
  IndexedDBContext,
  IndexedDBContextType,
} from "../../../utils/context/indexedDBContext";
import Utils from "../../../utils/Utils";
import Champ from "../../../components/ui/Champ";

interface Props {

}

const PrescriptionVarilux2: React.FC<Props> = () => {
  //variables, hooks
  const dispatch = useDispatch();
  const { t } = useTranslation(useSelector(getApp));
  const { prescriptionVarilux, view, prescriptionVideoPlay } =
    useSelector(getVariluxG9);
  const [B, E] = useBEM("varilux-prescription");
  const { productSelected, sphereMean, addition } = prescriptionVarilux;
  const { getAddSegmentation, getAmetropia, getCategoryPercentages } =
    useVariluxMatrix();
  const { get, insert } = React.useContext(
    IndexedDBContext
  ) as IndexedDBContextType;
  const { downloadAssetBase64 } = Utils();

  const { disableNext } = useVariluxDisableButton();
  const [showSkip, setShowSkip] = useState<boolean>(false);
  const [asset, setAsset] = useState<any>(null);
  const videoRef = useRef<any>();
  const newAssetsURL = (url: string) => {
    return `${
      settings[settings.environment].newAssetsUrl
    }VariluxG9/demo/${url}`;
  };
  const prescriptionVideo = newAssetsURL(
    "PRESCRIPTIONS/varilux_prescription_video.mp4"
  );

  
  useEffect(() => {
    let mounted = true
    const downloadPrescriptionAsset = async () => {
      const isPrescriptionVideoDownloaded = await isAlreadyPreloaded(
        "PRESCRIPTIONS",
        0
      );
      if (!isPrescriptionVideoDownloaded) {
        const base64 = await downloadAssetBase64(prescriptionVideo);
        await insert("variluxG9Store", {
          id: "PRESCRIPTIONS_0",
          asset: base64,
        });
        const asset = await get("variluxG9Store", "PRESCRIPTIONS_0");
        if (asset.asset) setAsset(asset.asset);
      } else {
        const asset = await get("variluxG9Store", "PRESCRIPTIONS_0");
        if (mounted && asset.asset) setAsset(asset.asset);
      }
    };
    downloadPrescriptionAsset();

    return () => {mounted = false}
  }, []);


  const isAlreadyPreloaded = async (category: string, i: number) => {
    const id = `${category}_${i}`;
    const asset = await get("variluxG9Store", id);
    return asset ? true : false;
  };

  // functions
  const onSelectChamp = (option: string) => {
    if(option === productSelected){
        dispatch(variluxgG9Slice.actions.selectProduct("none" as PrescriptionProductTypeVarilux));
        return
    }
    dispatch(
      variluxgG9Slice.actions.selectProduct(
        option as PrescriptionProductTypeVarilux
      )
    );
  };

  const next = () => {
    dispatch(variluxgG9Slice.actions.setPrescriptionVideoPlay(true));
  };

  const delayedShowSkip = () => {
    setTimeout(() => {
      setShowSkip(true);
    }, 3000);
  };

  const videoSkip = () => {
    if (productSelected === PrescriptionProductTypeVarilux.NONE) {
      dispatch(variluxgG9Slice.actions.setLensCategorySelected(false));
    }
    if (
      sphereMean &&
      addition &&
      productSelected !== PrescriptionProductTypeVarilux.NONE
    ) {
      const add = getAddSegmentation(addition);
      const ametropia = getAmetropia(sphereMean);
      if (ametropia !== null && add !== null) {
        const categoryPercentages = getCategoryPercentages(
          ametropia,
          add,
          productSelected
        );
        dispatch(variluxgG9Slice.actions.setEyeCondition(ametropia));
        dispatch(variluxgG9Slice.actions.setAddSegmentation(add));
        dispatch(
          variluxgG9Slice.actions.setCategoryPercentages(categoryPercentages)
        );
        dispatch(variluxgG9Slice.actions.setLensCategorySelected(true));
      }
    }
    dispatch(variluxgG9Slice.actions.setPrescriptionVideoPlay(false));
    dispatch(variluxgG9Slice.actions.changeView("demonstrations"));
  };

  return (
    <div className={B()}>
      {!prescriptionVideoPlay ? (
        <Dialogue flexColumn>
          {/* prescription part */}
          <div className={E('header')}>
            <span className={E('header-title')}>
                {t('current_progress_lens', 'Current progressive lenses')}
            </span>
            <span className={E('question-type')}>
              {t('need_explorer_single_choice_question','Single choice question')}
            </span>
          </div>
          {/* choices */}
          <div className={E("choices")}>
            {prescriptionOptions.map((choice, i) => {
                return (
                    <Champ
                        gtmId={choice.gtmId}
                        text={t(choice.value, choice.label)}
                        key={i}
                        selected={choice.value === productSelected}
                        click={() => onSelectChamp(choice.value)}
                  />
                )
            })}
          </div>

          {/* bottom */}
          <div className={E("bottom")}>
            <span className={E("bottom-next")}>
              {/* {t("varilux__mandatory", " *Mandatory ")} */}
            </span>

            <div className={E("bottom-buttons")}>
              {/* to do : confirm if will remove skip button */}
              {/* <div className={E('bottom-buttons-skip')}>
                  {t('skip_button', 'Skip')}
                  <Icon name='greater-than'/>
                </div>  */}

              <Button
                text={t("personalize_your_lenses_pyl_next",'Next')}
                icon="right-arrow-circle-purple"
                className={E("next-button")}
                outlined={false}
                type="simple"
                click={next}
                disabled={disableNext(view)}
                id="current_lenses_next_button"
              />
            </div>
          </div>
        </Dialogue>
      ) : (
        <>
          {/* <div className={E('video-container')} style={{top: aboveScreenHeight ? '0' : `${topStyle}px`}}> */}
          <div className={E("video-container")}>
            <video
              ref={videoRef}
              autoPlay={true}
              loop={false}
              playsInline
              muted
              onPlay={delayedShowSkip}
            >
              <source src={asset} />
            </video>
          </div>
          {showSkip && (
            <div className={E("skip")} id="current_lenses_skip_button" onClick={videoSkip}>
              {t("skip_button", "Skip")}
              <Icon name="greater-than" gtmId="current_lenses_skip_button" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PrescriptionVarilux2;
