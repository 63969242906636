import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import { getApp } from '../../../store/slices/app.slice';
import variluxgG9Slice from '../../../store/slices/variluxG9.slice';
import useBEM from '../../../utils/hooks/useBEM';
// import useGoto from '../../../utils/hooks/useGoto';
import useTranslation from '../../../utils/hooks/useTranslation';
// import routes from '../../../constants/routes.constant'
import { useEffect, useState } from 'react';

const ConclusionVarilux = () => {
  const dispatch = useDispatch()
  const [B, E] = useBEM('varilux-conclusion')
  const { t } = useTranslation(useSelector(getApp));
  const { translation } = useSelector(getApp)
  const [endExperience, setEndExperience] = useState<boolean>(false)
//   const goto = useGoto()


  const nextStep = () => {
    //dispatch(variluxgG9Slice.actions.resetData())
    //goto.route(routes.home)
    window.location.replace(window.name)
  }

  const restart = () => {
    dispatch(variluxgG9Slice.actions.resetData())
    dispatch(variluxgG9Slice.actions.changeView('intro'))
  }

  useEffect( () => {
    if(window.name){
      setEndExperience(true)
    }
  },[window.name])


  const setKoreanHeaderText = () => {
    if (translation?.country === 'Korea') {
      return (
        <p>
          <span>바리락스 최고의 </span> &nbsp;
          <span className={E('text-gradient')}>
            누진다초점 렌즈
          </span>
        </p>
      )
    } return (
      <p>
        <span>
          {t('varilux_the_best_overall', 'The best overall')}
        </span>
        &nbsp;
        <span className={E('text-gradient')}>
          {t('varilux_progressive_lens', 'progressive lens*')}
        </span>
      </p>
    )
  }

  return (
    <div className={B()}>
      <div className={E('logo')}>
        <Icon name='variluxG9-logo-final' type='png' />
      </div>
      <div className={E('text')}>
        {/* <p>
          <span>
            {t('varilux_the_best_overall', 'The best overall')}
          </span>
          &nbsp;
          <span className={E('text-gradient')}>
            {t('varilux_progressive_lens', 'progressive lens*')}
          </span>
        </p> */}
        {setKoreanHeaderText()}
      </div>
      <div className={E('buttons')}>
        <Button
          text={t('varilux_btn_next_patient', 'New Demonstration')}
          className={E('buttons-btn-restart')}
          icon="right-arrow-circle-purple"
          outlined={false}
          type='simple'
          click={restart}
          id='varilux_btn_new_demo'
        />
        {
          endExperience &&
          <Button
            text={t('varilux_btn_end_experience', 'End Demonstration')}
            icon="right-arrow-circle-purple"
            className={E('buttons-btn')}
            outlined={false}
            type='simple'
            click={nextStep}
            id='varilux_btn_end_experience'
          />
        }
      </div>
      <div className={E('disclaimer')}>
        <i>{t('calculation_based_on_lenses', '*Disclaimer: Essilor R&D simulations - 2022 - calculation based on lenses measurements weighted by the level of importance of each criteria for progressive lenses declared by users (Quantitative Consumer study - Ipsos - Q1 2022 - BR/FR/IT/UK/US - n=4000 progressive lens wearers) - Simulations done on most relevant competitive brands : brands with good level of awareness among consumers (Consumer Lens Brand Tracking - Ipsos - Q3 2022 - BR/CA/CN/FR/IN/IT/UK/US - n=8000) & offering premium progressive lenses.')}​</i>
      </div>
    </div>
  )
}

export default ConclusionVarilux