import IVariluxChoices from "../../interface/varilux-choices.interface"

const variluxChoices:IVariluxChoices[] = [
    {
        id:1,
        label:['varilux_choice_difficulty_sharpness','Difficulty to maintain sharpness'],
        selected:false,
        gtmId: 'varilux_choice_difficulty_sharpness'
    },
    {
        id:2,
        label:['varilux_choice_sensation','Sensation of swim effect while moving'],
        selected:false,
        gtmId: 'varilux_choice_sensation'
    },
    {
        id:3,
        label:['varilux_choice_restricted','Restricted field of vision'],
        selected:false,
        gtmId: 'varilux_choice_restricted'
    },
    {
        id:4,
        label:['varilux_choice_difficulty_light','Difficulty to see sharply in low light conditions'],
        selected:false,
        gtmId: 'varilux_choice_difficulty_light'
    },
    {
        id:5,
        label:['varilux_choice_limited_posture','Limited postural changes'],
        selected:false,
        gtmId: 'varilux_choice_limited_posture'
    },
    {
        id:6,
        label:['varilux_choice_none','None, I am very satisfied with my progressive lenses'],
        selected:false,
        gtmId: 'varilux_choice_none'
    },
]


export default variluxChoices