import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import useBEM from '../utils/hooks/useBEM';
import SvgIcon from './../components/ui/svg-icon/SvgIcon';
import { useSelector, useDispatch } from 'react-redux';
import appSlice, { getApp } from '../store/slices/app.slice';

// import { IndexedDBContext, IndexedDBContextType } from '../utils/context/indexedDBContext';

interface Props {
    show?: boolean,
    onClose?: () => void,
    hideCloseButton?: boolean,
    closeModalOnOutsideClick?: boolean,
    rightHeaderDetail?: string,
    height?: string,
    width?: string,

    radius?: string,
    className?: string,
    transparent?: boolean,
    style?: {
        width?: string,
        heigth?: string,
        borderRadius?: string
    },
    noPadding?: boolean
    closeButtonColor?:string,
}

const Modal : React.FC<Props>  = ({
    onClose,
    show = false,
    children,
    hideCloseButton = false,
    closeModalOnOutsideClick = true,
    rightHeaderDetail = "",
    height,
    width,
    radius,
    className = '',
    transparent = false,
    style,
    noPadding = false,
    closeButtonColor,
}) =>  {

    // VARIABLES
    const [B, E] = useBEM('modal');
    const portal: any = document.getElementById("portal");
    const [isWithBanner, withBanner] = useState(false);
    const { experience } = useSelector(getApp);
    // const {experience, isModalOpen, } = useSelector(getApp);
    const dispatch = useDispatch();
    // const {get} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    // HOOKS
    // const videoRef = useRef<any>();
    useEffect(() => {
        if(!show || !style)return;
        dispatch(appSlice.actions.setIsModalOpen(true))
        const bannerElement = document.getElementById('onetrust-banner-sdk');
        const visible = (bannerElement && bannerElement.style.display !== 'none');
        withBanner(visible as boolean);
    }, [show])


    // const [assetSource, setAssetSource] = useState<any>()

    // METHODS
    const onCloseHandler = () => {
        if(onClose){
            dispatch(appSlice.actions.setIsModalOpen(false))
            onClose();
        }
    }

    const overlayOnClickHandler = () => {
        if(closeModalOnOutsideClick) onCloseHandler()
    }

    const customStyles = () => {
        return style ? style : {
            width,
            height,
            borderRadius: radius
        }
    }

    const mofifierClass = () => {
        let classes: string[] = [];
        if(transparent) classes.push('transparent');
        if(noPadding) classes.push('no-padding');
        if(isWithBanner)classes.push('with-banner');
        classes.push(experience);
        return classes;
    }

    if(!show) return null;
    return ReactDOM.createPortal(
        <>
            <div className="modal-overlay" onClick={overlayOnClickHandler}></div>
            <div className={`${B(mofifierClass())} ${className}`} style={customStyles()}>
       
                {
                    !hideCloseButton && 
                    
                    // <Icon name="close-gray" className={E('close')} />
                    <div 
                        className={E('close')} 
                        onClick={() => onCloseHandler()}
                    >
                        <SvgIcon 
                            name='close'                             
                            height='1.25rem'
                            color={closeButtonColor}
                        />   
                    </div>                 
                }

                

                {
                    hideCloseButton && rightHeaderDetail !== undefined && 
                    <p className={E('right-header-detail')}>{rightHeaderDetail}</p>
                }


                <div className={E('content')}>
                    {children}
                </div>
                
                
            </div>
        </>,
        portal
    )
}

export default Modal;