const stackedGroup = `
        <g id="LOT-1-/-Home-/-EssilorCompanion_V1_Home_00" transform="translate(-911.000000, -659.000000)">
            <g id="Padding-Group-5" transform="translate(373.000000, 631.000000)">
                <g id="Group-4" transform="translate(0.840618, 0.000000)">
                    <g id="Group-3" transform="translate(25.000000, 15.000000)">
                        <g id="Stacked-Group-3" transform="translate(192.257797, 0.000000)">
                            <g id="Group-2" transform="translate(276.000000, 0.000000)">
                                <g id="Stacked-Group-3" transform="translate(15.000000, 12.000000)">
                                    <g transform="translate(29.000000, 1.000000)">
                                        <g>
                                            <circle id="Oval" cx="1.5" cy="1.5" r="1.5"></circle>
                                            <circle id="Oval-Copy" cx="7.5" cy="1.5" r="1.5"></circle>
                                            <circle id="Oval-Copy-2" cx="13.5" cy="1.5" r="1.5"></circle>
                                        </g>
                                        <g id="Stacked-Group-3-Copy" transform="translate(0.000000, 6.000000)">
                                            <circle id="Oval" cx="1.5" cy="1.5" r="1.5"></circle>
                                            <circle id="Oval-Copy" cx="7.5" cy="1.5" r="1.5"></circle>
                                            <circle id="Oval-Copy-2" cx="13.5" cy="1.5" r="1.5"></circle>
                                        </g>
                                        <g id="Stacked-Group-3-Copy-2" transform="translate(0.000000, 12.000000)">
                                            <circle id="Oval" cx="1.5" cy="1.5" r="1.5"></circle>
                                            <circle id="Oval-Copy" cx="7.5" cy="1.5" r="1.5"></circle>
                                            <circle id="Oval-Copy-2" cx="13.5" cy="1.5" r="1.5"></circle>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
`;

export default stackedGroup;