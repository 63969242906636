const prescriptionOptions = [
    // {
    //     label: '',
    //     value: 'none',
    //     videoIndex: {
    //         extended: 0,
    //         stabilized: 0,
    //         panoramic: 0,
    //         instant: 0,
    //         sharp: 0,
    //     }
    // },
    {
        label: 'Varilux® X series™',
        value: 'varilux_x_series',
        videoIndex: {            
            instant: 2,
            stabilized: 1,
            panoramic: 1,
            sharp: 1,
            extended: 1,
        },
        gtmId: "current_lenses_varilux_x_series"
    },
    {
        label: 'Varilux® Physio® 3.0',
        value: 'varilux_physio_30',
        videoIndex: {
            instant: 3,
            stabilized: 2,
            panoramic: 2,
            sharp: 1,
            extended: 2,
        },
        gtmId: "current_lenses_varilux_physio_30"
    },
    {
        label: 'Varilux® Comfort Max',
        value: 'varilux_comfort',
        videoIndex: {
            instant: 3,
            stabilized: 2,
            panoramic: 3,
            sharp: 1,
            extended: 1,
        },
        gtmId: "current_lenses_varilux_comfort"
    },
    {
        label: 'Premium Progressive Lenses',
        value: 'standard_progressive_lens',
        videoIndex: {
            instant: 3,
            stabilized: 2,
            panoramic: 3,
            sharp: 3,
            extended: 3,
        },
        gtmId: "current_lenses_standard_progressive_lens"
    },
]


export default prescriptionOptions