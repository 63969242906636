const KIDS_THEMES = [
    {
        id: 'red',
        main: '#ec7281',
        dark: '#ea5c6d',
        bubbleMain: '#ec6275',
        bubbleSub: '#ea5264',
    },
    {
        id: 'blue',
        main: '#3faaf7',
        dark: '#2b86d7',
        bubbleMain: '#2594ea',
        bubbleSub: '#2b80ce',
    },
    {
        id: 'green',
        main: '#6bd6c9',
        dark: '#63c6b9',
        bubbleMain: '#64ccbf',
        bubbleSub: '#5dc3b8',
    },
    {
        id: 'orange',
        main: '#f2a76b',
        dark: '#f09256',
        bubbleMain: '#ef9a5c',
        bubbleSub: '#f18d4d',
    },
    {
        id: 'purple',
        main: '#6b68c2',
        dark: '#5f5cac',
        bubbleMain: '#615eb5',
        bubbleSub: '#5b58a9',
    },
    {
        id: 'multi',
        main: '#0086d6',
        dark: '#0086d6',
        bubbleMain: '#0086d6',
        bubbleSub: '#0086d6',
    },
]

export {
    KIDS_THEMES
}


