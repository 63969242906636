import Modal from "../../../../components/Modal";
import useBEM from "../../../../utils/hooks/useBEM";
import { useState } from "react";
import useTranslation from "../../../../utils/hooks/useTranslation";
import { useSelector } from "react-redux";
import { getApp } from "../../../../store/slices/app.slice";
import ProductCardVariluxModalContent from "./ProductCardVariluxModalContent";

const ProductCardVariluxModal = (props: any) => {
    const { t } = useTranslation(useSelector(getApp));
    const [B, E] = useBEM('varilux-demonstrations-modal');
    const [showModal, setShowModal] = useState<boolean>(true);
    const modalStyle = {
        width: "100%",
        height: "69.8rem",
        borderRadius: "15px"
    }
    return (
        <>
            {
                showModal ?
                    <Modal
                        style={modalStyle}
                        noPadding={true}
                        show={props.showDemo}
                        onClose={() => props.setShowDemo(false)}
                        hideCloseButton={false}
                        closeModalOnOutsideClick={true}
                        className={B()}
                    >
                        <ProductCardVariluxModalContent
                            E={E}
                            t={t}
                            showModal={showModal}
                            setShowModal={setShowModal}
                            {...props}
                        />
                    </Modal>
                    : <ProductCardVariluxModalContent
                        E={E}
                        t={t}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        {...props}
                    />

            }
        </>

    )
}
export default ProductCardVariluxModal;