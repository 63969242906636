import Icon_1 from "../../../../assets/svg/varilux-g9/icons/control-1.svg";
import Icon_2 from "../../../../assets/svg/varilux-g9/icons/control-2.svg";
import Icon_3 from "../../../../assets/svg/varilux-g9/icons/control-3.svg";
import Icon_4 from "../../../../assets/svg/varilux-g9/icons/control-4.svg";
import Icon_5 from "../../../../assets/svg/varilux-g9/icons/control-5.svg";
import Icon_1_white from "../../../../assets/svg/varilux-g9/icons/control-1-white.svg";
import Icon_2_white from "../../../../assets/svg/varilux-g9/icons/control-2-white.svg";
import Icon_3_white from "../../../../assets/svg/varilux-g9/icons/control-3-white.svg";
import Icon_4_white from "../../../../assets/svg/varilux-g9/icons/control-4-white.svg";
import Icon_5_white from "../../../../assets/svg/varilux-g9/icons/control-5-white.svg";
import Icon_fill from "../../../../assets/svg/varilux-g9/icons/fill.svg";
// import Icon_fill_white from "../../../../assets/svg/varilux-g9/icons/fill-white.svg";
// import Icon_ZoomIn from "../../../../assets/images/demonstrations/icons/zoom-in.png";
// import Icon_ZoomOut from "../../../../assets/images/demonstrations/icons/zoom-out.png";
import Loading from "../../../../components/Loading";
import variluxProducts from "../../../../utils/varilux-products";
import { VariluxPreviewType } from "../../../../interface/varilux-product-card.interface";
import { useSelector } from "react-redux";
import { getVariluxG9 } from "../../../../store/slices/variluxG9.slice";

const ProductCardVariluxModalContent = ({ E, t, showModal, setShowModal, item, isDownloading, index, handlControlClick }: any) => {

    const { previewItems } = item;
    const products = variluxProducts;
    const {
        needExplorerChoices,
    } = useSelector(getVariluxG9);

    return (
        <div className={E(`zoom_${showModal ? 'out' : 'in'}`)}>
            {
                showModal && (
                    <div className={E('head')}>
                        {/* <span className="text-placement">{t('demo', 'Demo')}</span> */}
                    </div>
                )
            }
            <div className={E('canvas')}>
                {
                    isDownloading ? <Loading show={true} /> :
                        previewItems?.list?.map((item: any, index: number) => {
                            return (
                                <div className={E('canvas-item')} key={index}>
                                    {
                                        previewItems?.type === VariluxPreviewType.IMAGE ?
                                            <img src={item.source} alt='Asset' /> :
                                            <video
                                                autoPlay={true}
                                                loop={true}
                                                playsInline
                                                muted
                                                src={item.source}
                                            />
                                    }
                                    <div className={E('canvas-item-title-container')}>
                                        <div className={E('canvas-item-title')}>{item.title}</div>
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
            <div className={E('controls')}>
                {
                    !isDownloading && (
                        <>
                            <div className={E('controls-left')}>
                                <div className={E('controls-icon' + (index === 0 ? ' active' : ' pointer'))} id={products[0].title[0]}>
                                    {
                                        index === 0
                                            ? <img style={{ width: 40 }} id={products[0].title[0]} src={Icon_1_white} alt={products[0].title[0]} />
                                            : <img style={{ width: 40 }} id={products[0].title[0]} src={Icon_1} onClick={() => handlControlClick(0)} alt={products[0].title[0]} />
                                    }
                                    {
                                        needExplorerChoices[0].selected && <img className={E('fill-icon')} src={Icon_fill} id={products[0].title[0]} alt={products[0].title[0]} />
                                    }
                                </div>
                                <div className={E('controls-icon' + (index === 1 ? ' active' : ' pointer'))} id={products[1].title[0]}>
                                    {
                                        index === 1
                                            ? <img style={{ width: 40, marginTop: 5 }} src={Icon_2_white} id={products[1].title[0]} alt={products[1].title[0]} />
                                            : <img src={Icon_2} onClick={() => handlControlClick(1)} id={products[1].title[0]} alt={products[1].title[0]} />
                                    }
                                    {
                                        needExplorerChoices[1].selected && <img className={E('fill-icon')} src={Icon_fill} id={products[1].title[0]} alt={products[1].title[0]} />
                                    }
                                </div>
                                <div className={E('controls-icon' + (index === 2 ? ' active' : ' pointer'))} id={products[2].title[0]}>
                                    {
                                        index === 2
                                            ? <img style={{ width: 40 }} src={Icon_3_white} id={products[2].title[0]} alt={products[2].title[0]} />
                                            : <img src={Icon_3} width={39} onClick={() => handlControlClick(2)} id={products[2].title[0]} alt={products[2].title[0]} />
                                    }
                                    {
                                        needExplorerChoices[2].selected && <img className={E('fill-icon')} src={Icon_fill} id={products[2].title[0]} alt={products[2].title[0]} />
                                    }
                                </div>
                                <div className={E('controls-icon' + (index === 3 ? ' active' : ' pointer'))} id={products[3].title[0]}>
                                    {
                                        index === 3
                                            ? <img src={Icon_4_white} id={products[3].title[0]} alt={products[3].title[0]} />
                                            : <img src={Icon_4} onClick={() => handlControlClick(3)} id={products[3].title[0]} alt={products[3].title[0]} />
                                    }
                                    {
                                        needExplorerChoices[3].selected && <img className={E('fill-icon')} src={Icon_fill} id={products[3].title[0]} alt={products[3].title[0]} />
                                    }
                                </div>
                                <div className={E('controls-icon' + (index === 4 ? ' active' : ' pointer'))} id={products[4].title[0]}>
                                    {
                                        index === 4
                                            ? <img src={Icon_5_white} id={products[4].title[0]} alt={products[4].title[0]} />
                                            : <img src={Icon_5} onClick={() => handlControlClick(4)} id={products[4].title[0]} alt={products[4].title[0]} />
                                    }
                                    {
                                        needExplorerChoices[4].selected && <img className={E('fill-icon')} id={products[4].title[0]} src={Icon_fill} alt={products[4].title[0]} />
                                    }
                                </div>
                            </div>
                            <div className={E('controls-right')}>
                                <div className={E('controls-left-text')}><span>{t(item.title[0], item.title[1])}</span></div>
                                {/* <div className={E('controls-right-text')}><span>{showModal ? t('full-screen', 'Full screen') : t('quit-full-screen', 'Quit full screen')}</span></div>
                                <div className={E('controls-icon-zoom')} onClick={() => setShowModal(!showModal)}><img style={{ padding: 10 }} src={showModal ? Icon_ZoomIn : Icon_ZoomOut} /></div> */}
                            </div>
                        </>
                    )
                }

            </div>
        </div>
    )
}
export default ProductCardVariluxModalContent;