import { IconType } from "./IconType";
import stackedGroup from './icons/stacked-group';

const accountCircle = `M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z`;
const gps = `M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z`;
const close = `M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z`;
const add  = `M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z`;
const minus = `M19 13H5v-2h14v2z`;
const arrowRight = `M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z`;
const arrowLeft = `M11.67 3.87 9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z`;
const check = `M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z`;
const qrcode = `M19.26 13.952c.408 0 .74.331.74.74v4.567c0 .41-.332.741-.74.741h-4.567c-.41 0-.74-.332-.74-.74 0-.41.33-.741.74-.741h3.826v-3.826c0-.41.331-.741.74-.741zm-18.52 0c.41 0 .742.331.742.74v3.827h3.825c.41 0 .741.331.741.74 0 .41-.332.741-.74.741H.74C.33 20 0 19.668 0 19.26v-4.567c0-.41.332-.741.74-.741zm10.014-4.707v5.658h5.657v1.508H9.246v-5.657H3.589V9.245h7.165zM8.08 11.921v4.49h-4.49v-4.49h4.49zM6.57 13.43H5.097v1.474h1.474v-1.474zm9.84-4.183v4.184h-1.508v-2.676h-1.474v2.676h-1.508V9.246h4.49zM8.08 3.589v4.49h-4.49v-4.49h4.49zm8.332 0v4.49h-4.49v-4.49h4.49zm-5.657 0v4.49H9.246v-4.49h1.508zM6.571 5.097H5.097V6.57h1.474V5.097zm8.332 0H13.43V6.57h1.473V5.097zM5.307 0c.41 0 .741.332.741.74 0 .41-.331.742-.74.742H1.48v3.825c0 .409-.331.74-.74.74-.41 0-.741-.331-.741-.74V.741C0 .33.332 0 .74 0h4.567zM19.26 0c.41 0 .741.332.741.74v4.567c0 .409-.332.74-.74.74-.41 0-.741-.331-.741-.74V1.482h-3.826c-.41 0-.74-.332-.74-.741 0-.41.33-.74.74-.74h4.566z" transform="translate(-822 -656) translate(373 631) translate(.84) translate(30 15) translate(334.258) translate(15 10) translate(69)`

export const SvgIcons: {
    [key in IconType]: string
} = {
    gps,
    accountCircle,
    close,
    add,
    arrowRight,
    arrowLeft,
    minus,
    check,
    qrcode,
    stackedGroup
}
