const multipleLanguages = ["enCA","caCA", "chCH", "beBE", "arBA", "cuCA"];
const elacES = ["doDO","ecEC","svSV","gtGT","hnHN","paPA","pePE", "niNI", "crCR", "veZU" ];
const elacEN = ["baBA","barBAR","guGU","jaJA","trTR", "enUK", "anBA", "caIS", "grDA", "saLU"];
const languageCode = {
    anBA:{
        code: "anBA",
        country: "antigua and barbuda",
        translation: {}
    },
    caIS:{
        code: "caIS",
        country: "cayman islands",
        translation: {}
    },
    grDA:{
        code: "grDA",
        country: "grenada",
        translation: {}
    },
    saLU:{
        code: "saLU",
        country: "saint lucia",
        translation: {}
    },
    veZU:{
        code: "veZU",
        country: "venezuela",
        translation: {}
    },
    baBA:{
        code: "baBA",
        country: "Bahamas",
        translation: {}
    },
    barBAR:{
        code: "barBAR",
        country: "Barbados",
        translation: {}
    },
    guGU:{
        code: "guGU",
        country: "Guyana",
        translation: {}
    },
    jaJA:{
        code: "jaJA",
        country: "Jamaica",
        translation: {}
    },
    trTR:{
        code: "trTR",
        country: "Trinidad and Tobago",
        translation: {}
    },
    niNI:{
        code: "niNI",
        country: "Nicaragua",
        translation: {}
    },
    crCR:{
        code: "crCR",
        country: "Costa Rica",
        translation: {}
    },
    enUK:{
        code: "enUK",
        country: "united kingdom",
        translation: {}
    },
    doDO:{
        code: "doDO",
            country: "dominican republic",
            multipleLanguage: {
                esBO: {
                    code: "esBO",
                    language: "Spanish"
                },
            },
            translation: {}
        },
        ecEC:{
        code: "ecEC",
            country: "ecuador",
            multipleLanguage: {
                esBO: {
                    code: "esBO",
                    language: "Spanish"
                },
            },
            translation: {}
        },
        svSV:{
        code: "svSV",
            country: "el salvador",
            multipleLanguage: {
                esBO: {
                    code: "esBO",
                    language: "Spanish"
                },
            },
            translation: {}
        },
        gtGT:{
        code: "gtGT",
            country: "guatemala",
            multipleLanguage: {
                esBO: {
                    code: "esBO",
                    language: "Spanish"
                },
            },
            translation: {}
        },
        hnHN:{
        code: "hnHN",
            country: "honduras",
            multipleLanguage: {
                esBO: {
                    code: "esBO",
                    language: "Spanish"
                },
            },
            translation: {}
        },
        paPA:{
        code: "paPA",
            country: "panama",
            translation: {}
        },
        pePE:{
        code: "pePE",
            country: "peru",
            multipleLanguage: {
                esBO: {
                    code: "esBO",
                    language: "Spanish"
                },
            },
            translation: {}
        },
        // caCA:{
        //     code: "caCA",
        //             country: "canada",
        //             multipleLanguage: {
        //                 enUS: {
        //                     code: "enUS",
        //                     language: "English"
        //                 },
        //                 frFR: {
        //                     code: "frFR",
        //                     language: "French"
        //                 }
        //             },
        //             translation: {}
        // },
        // chCH : {
        //     code: "chCH",
        //     country: "switzerland",
        //     multipleLanguage: {
        //         frFR: {
        //             code: "frFR",
        //             language: "French"
        //         },
        //         deDE: {
        //             code: "deDE",
        //             language: "German"
        //         }
        //     },
        //     translation: {}
        // },
        // beBE: {
        //     code: "beBE",
        //     country: "belgium",
        //     multipleLanguage: {
        //         deDE: {
        //             code: "deDE",
        //             language: "German"
        //         },
        //         frFR: {
        //             code: "frFR",
        //             language: "French"
        //         },
        //         itIT: {
        //             code: "itIT",
        //             language: "Italy"
        //         },
                
        //     },
        //     translation: {}
        // }
}

export {
    languageCode,
    multipleLanguages,
    elacES,
    elacEN
}

