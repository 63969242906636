import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import RouterLink from '../../../components/ui/RouterLink';
import routes from '../../../constants/routes.constant';
import useBEM from '../../../utils/hooks/useBEM';
import logo from '../../../assets/png/logo-white.png';
import Icon from '../../../components/ui/Icon';
import LanguageSetting from './LanguageSetting';
import variluxgG9Slice from '../../../store/slices/variluxG9.slice';
import RefreshIcon from "../../../assets/svg/varilux-g9/icons/refresh.svg";
//import DemonstrationPopup from './DemonstrationPopup';
//import { getApp } from '../../../store/slices/app.slice';


interface Props {

}

const VarNavbarHeader: React.FC<Props> = () => {
  const [B, E] = useBEM('varilux-navbar-head')
  const [supported, setSupported] = useState<any>(false)
  const [installed, setInstalled] = useState<any>(null)
  const dispatch = useDispatch()
  //const { translation } = useSelector(getApp)

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault()
      setSupported(true)
      setInstalled(e)
    }
    window.addEventListener('beforeinstallprompt', (handler))
    return () => window.removeEventListener("transitionend", handler)
  }, [])

  const openDownloadPopUp = () => {
    dispatch(variluxgG9Slice.actions.setShowDownloadPopup(true))
  }

  const handleRefresh = () => {
    window.location.reload();
  }


  return (
    <div className={B()}>
      <RouterLink className={E('logo')}
        route={routes.home}
      >
        <img src={logo} alt="essilor-logo" />
      </RouterLink>

      <Icon name='variluxG9-logo-final' type='png' className={E('main-logo')} />

      {/* download and language selection */}
      <div className={E('right')}>
        <div className={E('right-refresh')} onClick={handleRefresh}>
          <img src={RefreshIcon} alt='refresh'/>
        </div>
        <span className={E('right-divider')}></span>
        {
          supported && installed !== null && <>
            <div onClick={openDownloadPopUp} className={E('right-download')}>
              <Icon name='varilux-download' />
            </div>
            <span className={E('right-divider')}></span>
          </>
        }
        {/* {
          (translation?.country === "Germany") && (
            <>
              <DemonstrationPopup />
              <span className={E('right-divider')}></span>
            </>
          )
        } */}
        <LanguageSetting />
      </div>
    </div>
  )
}

export default VarNavbarHeader