import * as React from "react";

type ProviderProps = {
    db: IDBDatabase,
    children: React.ReactNode
}

export interface IndexedDBContextType {
    db: any,
    get: (table: string, key: string) => Promise<any>,
    update : (table: string, data: any) => void,
    insert : (table: string, data: any) => void
}

export const IndexedDBContext = React.createContext< IndexedDBContextType | undefined >(undefined)


function IndexedDBProvider({db, children}: ProviderProps) {
    
  const database = db;

    const getData = (table: string, key: string) => {
        const tx = database.transaction(table,'readonly');
        const store = tx.objectStore(table);
        const getRequest = store.get(key);

        return new Promise((resolved, reject) => {
            getRequest.onsuccess = (ev: any) => {
                resolved(ev.target.result);
            }
            getRequest.onerror = (err: any) => { 
                reject(err);
            }
        });        
    }

    // const updateData = (database:IDBDatabase,table: string, data: any) => {
    const updateData = (table: string, data: any) => {      
        const tx = database.transaction(table,'readwrite');
        const store = tx.objectStore(table);
        const request = store.put(data);
        request.onsuccess = () => { }
        request.onerror = () => { }
    }

    const insertData = (table: string, data: any) => {
      const tx = database.transaction(table,'readwrite');
      const store = tx.objectStore(table);
      const request = store.add(data);
      request.onsuccess = () => { }
      request.onerror = () => { }
    }

    const value = {
        db: database,
        get: getData,
        update: updateData,
        insert: insertData
    }
    return (
      <IndexedDBContext.Provider value={value}>
        {children}
      </IndexedDBContext.Provider>
    )
}

export default IndexedDBProvider