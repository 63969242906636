import iVariluxG9Background from "../../interface/variluxG9Background.interface";

type iView = 'intro' | 'needExplorer' | 'prescription' | 'demonstrations' | 'conclusion'

    

const useVariluxBackground  = (source:string[], view:iView) =>  {

   const backgrounds:iVariluxG9Background = {
    intro:{
      background:source[0]
    },
    needExplorer:{
      background:source[1]
    },
    prescription:{
      background:source[1]
    },
    demonstrations:{
      background:source[0]
    },
    conclusion:{
      background:source[0]
    }
  }
    const background = backgrounds[view]?.background
    const backgroundStyle = {
        height:'100%',
        width:'100%',
        backgroundImage:`url(${background ?? source[0]})`,
        backgroundSize: 'cover',
        backgroundPosition:'center'
        }
    return {backgroundStyle}
}

export default useVariluxBackground;