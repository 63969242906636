import varilux_matrix from "../../constants/variluxG9/varilux-products-matrix";
import IVariluxResult from "../../interface/varilux-matrix-result.interface";
import PrescriptionAddSegmentationType from "../../types/prescription-add-segmentation-varilux.type";
import PrescriptionEyeConditionType from "../../types/prescription-eye-condition-varilux.type";
import PrescriptionProductTypeVarilux from "../../types/prescription-product-varilux.type";

type hookType = {
    getAmetropia: (value:number) => PrescriptionEyeConditionType | null
    getAddSegmentation: (n: number) => PrescriptionAddSegmentationType | null
    getCategoryPercentages:(ametropia:PrescriptionEyeConditionType, add:PrescriptionAddSegmentationType, product:PrescriptionProductTypeVarilux) => IVariluxResult | null
}

const useVariluxMatrix  = (): hookType =>  {

    const getAmetropia = (value:number) =>{
        if(value >= -3 && value < -0.75)return PrescriptionEyeConditionType.LOW_MYOPE

        if( value < -3)return PrescriptionEyeConditionType.HIGH_MYOPE

        if( value >= -0.75 && value <= 0.75 ) return PrescriptionEyeConditionType.EMMETROPIC

        if( value <= 3 && value > 0.75) return PrescriptionEyeConditionType.LOW_HYPEROPE

        if( value > 3) return PrescriptionEyeConditionType.HIGH_HYPEROPE

        return null
    }

    const getAddSegmentation = (n: number) => {
        if(n >= 2.5)return PrescriptionAddSegmentationType.HIGH_ADD

        if(n >= 2 && n < 2.5)return PrescriptionAddSegmentationType.MID_ADD

        if(n <= 1.75)return PrescriptionAddSegmentationType.LOW_ADD


        return null
    }


    const getCategoryPercentages = (ametropia:PrescriptionEyeConditionType | null,  add:PrescriptionAddSegmentationType, product:PrescriptionProductTypeVarilux) => {
        if(ametropia !== null && add !== null){
            const category = varilux_matrix[product][ametropia][add]
            const {extended, stabilized, panoramic, instant,sharp} = category
            return {
                extended,
                stabilized,
                panoramic,
                instant,
                sharp
            }
        }
        return null
    }



    return {
        getAmetropia,
        getAddSegmentation,
        getCategoryPercentages,
    }
}

export default useVariluxMatrix;