export enum VariluxPreviewType {
    IMAGE = "image",
    VIDEO = "video",
}
interface listItem {
    title: string,
    source: string,
}
interface previewItem {
    type: VariluxPreviewType,
    list: listItem[]
}
interface iVariluxProductCard {
    video: string | undefined;
    category: string,
    image: string,
    title: string | string[],
    isRecommended: boolean,
    isExclusive: boolean,
    previewItems: previewItem,
}

export default iVariluxProductCard;