import React, { useEffect, useState } from "react";
import useBEM from "../../../utils/hooks/useBEM";
import Modal from "../../../components/Modal";
import SearchIcon from "../../../assets/images/demonstrations/icons/Union.png";
import useTranslation from "../../../utils/hooks/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import appSlice, { getApp } from "../../../store/slices/app.slice";
import { IndexedDBContext, IndexedDBContextType } from "../../../utils/context/indexedDBContext";
import iG9CountryLanguages from "../../../interface/g9-country-languages.interface";


const LanguageSetting = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(useSelector(getApp));
    const [B, E] = useBEM('varilux-language-setting');
    const { language, g9CountryLanguages, g9Languages } = useSelector(getApp);
    const [selectedLanguage, setSelectedLanguage] = useState<string>(language);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [dataList, setDataList] = useState<iG9CountryLanguages[]>([]);
    const [location, setLocation] = useState<any>(localStorage.getItem('location') !== null ? localStorage.getItem('location') : 'default');
    const [continent, setContinent] = useState<any>("");
    const { get } = React.useContext(IndexedDBContext) as IndexedDBContextType;


    // HOOKS
    useEffect(() => {
        dispatch(appSlice.actions.setLanguage(location === 'default' ? 'enUK' : selectedLanguage));
        localStorage.setItem('location', location)
    }, [selectedLanguage, location])

    useEffect(() => {
        let strings = JSON.stringify(g9CountryLanguages);
        localStorage.setItem('countryLanguages', strings);
        setDataList(g9CountryLanguages);
    }, [g9CountryLanguages])


    const handleLocation = async (loc: any) => {
        if (loc === 'default') {
            setLocation('default')
            const engCountryCode = 'GB'
            const enCountryLanguageCode = 'enGB'
            let getLangfromDB = await get('languageStore', 'g9translations')
            let englishTranslation = getLangfromDB.value[engCountryCode].language[enCountryLanguageCode];
            dispatch(appSlice.actions.setTranslation({
                code: enCountryLanguageCode,
                country: 'USA',
                translation: englishTranslation,
            }));
        }

        setSelectedLanguage(loc === 'default' ? 'enUK' : loc)
        dispatch(appSlice.actions.setLanguage(loc === 'default' ? 'enUK' : loc));
        setLocation(loc);
    }

    const handlSearch = () => {
        let strings: any = localStorage.getItem('countryLanguages');
        const data = JSON.parse(strings);
        if (searchText === "") {
            setDataList(data);
        } else {
            let continents: any = [];

            data?.map((item: any) => {	
                let countries: any = [];
                item.countries.map((country: any) => {
                    if ((country.name.toLowerCase()).includes(searchText) || (country.language.name.toLowerCase()).includes(searchText)) {
                        countries.push(country)
                    }
                })
                if (countries.length > 0) {
                    item.countries = countries;
                    continents.push(item)
                }
            })
            setDataList(continents);
        }
    }

    const handleSelectLanguage = async (contenent: any, country: any) => {
        const countryCode = country.code;
        const countryLanguageCode = country.language.code + countryCode;
        const countryTranslationData = await get('languageStore', 'g9translations');
		dispatch(appSlice.actions.setTranslation({
            code: countryLanguageCode,
            country: country.name,
            translation: countryTranslationData.value[countryCode].language[countryLanguageCode]
        }));
        setLocation(country.language.code + country.code)
    }

    return (
        <>
            <div className={E('right-language')} onClick={() => setShowModal(true)}>
                <div className='language-text'>{selectedLanguage.substring(0,2).toUpperCase()}</div>
                <div className='arrow arrow--down'></div>
            </div>
            <Modal
                noPadding={showModal}
                show={showModal}
                onClose={() => setShowModal(false)}
                hideCloseButton={false}
                closeModalOnOutsideClick={true}
                className={B()}
            >
                <div className={E('title')}>{t('language_settings', 'Language Settings')}</div>
                <div className={E('section')}>
                    <label className={E('label')}>{t('select_your_location_language', 'Select your Location/Language')}</label>
                    <div className={E('search')}>
                        <div className={E('input')}>
                            <input className={E('value')} value={searchText} onChange={(e) => setSearchText((e.target.value).toLowerCase())} type="text" placeholder={t('search_location_language', "Search location/language")} />
                        </div>
                        <div className={E('button')} onClick={handlSearch}>
                            <span className={E('search-icon')}>
                                <img src={SearchIcon} alt="search-icon" />
                            </span>
                        </div>
                    </div>
                </div>

                <div className={E('section')}>
                    <label className={E('label')}>{t('suggested_location_language', "Suggested Location/Language")}</label>
                    {
                        g9Languages?.map((item, index) => {
                            return (
                                <div key={index}>
                                    {
                                        (index === 0 || item.code === location) && (
                                            <div className={E('suggested_language_button' + (item.code === location ? ' active' : ''))} onClick={() => handleLocation(item.code)}>
                                                <label>{t(item.name[0], item.name[1])}</label>
                                                <span className="outer-circle">
                                                    <span className="inner-circle"></span>
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                </div>

                <div className={E('section')}>
                    <label className={E('label')}>{t('choose_a_different_location_language', "Choose a different Location/Language")}</label>
                    {
                        (dataList.length > 0) && dataList?.map((item: any, index: number) => {
                            return (
                                <div key={index} className={E('different_language')}>
                                    <div className={E('different_language_button' + (item.continent === continent ? ' active' : ''))} onClick={() => setContinent(item.continent === continent ? "" : item.continent)}>
                                        <label>{item.continent.charAt(0).toUpperCase() + item.continent.slice(1)}</label>
                                        <span className={"arrow arrow--" + (item.continent === continent ? 'up' : 'down')}></span>
                                    </div>
                                    {
                                        (item.continent === continent) && item?.countries?.map((country: any, index: number) => {
                                            return (
                                                <div key={index} className={E('different_language_option')}
                                                    onClick={
                                                        () => {
                                                            setSelectedLanguage(country?.language?.code + country?.code);
                                                            handleSelectLanguage(item.continent, country);
                                                        }
                                                    }>
                                                    <label>{country?.name} - {country?.language?.name}
                                                        <span className="outer-circle fix-alignment">
                                                            {language === country?.language?.code + country?.code && <span className="inner-circle"></span>}
                                                        </span>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    <br />
                    <br />
                </div>
            </Modal>
        </>
    )
}
export default LanguageSetting;