import React from 'react';
import { useHistory } from 'react-router';
import iRoute from '../../interface/route.interface';

interface Props {
    route?: iRoute,
    className?: string,
    click?: () => void
}

const RouterLink : React.FC<Props>  = ({
    route,
    className,
    children,
    click
}) =>  {
    // VARIABLES
    const history = useHistory();

    // HOOKS

    // METHODS
    const onclickHandler = (event: React.MouseEvent) => {
        event.preventDefault();

        if(click) {
            click();
        }

        if(!route)return;
        history.push(route.path);
    }

    return (
        <a onClick={(e)=> onclickHandler(e)} className={className} href="/">
            {children}
        </a>
    )
}

export default RouterLink;