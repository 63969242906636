import { useSelector } from "react-redux";
import { PRESCRIPTION_RULES_VARILUX } from "../../constants/prescription.constants";
import { iView } from "../../store/interface/variluxG9.interface";
import { getVariluxG9 } from "../../store/slices/variluxG9.slice";
import PrescriptionProductTypeVarilux from "../../types/prescription-product-varilux.type";
import { iViewStep } from "../../pages/varilux-g9/components/ProgressBar";

type hookType = {
    disableNext: (currentView: iView) => boolean
    disableButtonNav: (disabledView: iView) => boolean
}


const useVariluxDisableButton = (): hookType => {
    //variables
    const { needExplorerChoices, prescriptionVarilux, view } = useSelector(getVariluxG9);
    const { productSelected, addition, values } = prescriptionVarilux

    // NEED EXPLORER
    const needExplorerHasSelectedValue = needExplorerChoices.every(choice => choice.selected === false)

    //PRESCRIPTION
    const sphereMax = PRESCRIPTION_RULES_VARILUX.standard.sphere.max
    const sphereMin = PRESCRIPTION_RULES_VARILUX.standard.sphere.min
    const additionMax = PRESCRIPTION_RULES_VARILUX.standard.addition.max
    const additionMin = PRESCRIPTION_RULES_VARILUX.standard.addition.min
    const od = values?.sphere?.od
    const os = values?.sphere?.os

    const disableNext = (currentView: iView) => {
        if (currentView === 'needExplorer') {
            return needExplorerHasSelectedValue
        }

        if (currentView === 'prescription') {
            if (productSelected === PrescriptionProductTypeVarilux.NONE) return true
            if (os && od && addition) {
                return !((os <= sphereMax && os >= sphereMin) &&
                    (od <= sphereMax && od >= sphereMin) &&
                    (addition <= additionMax && addition >= additionMin))
            }
            //if(!os && !od && !addition) return true
            // revert back to false when no logic on demo calculation without inputs on prescription is made.
            if (!os && !od && !addition) return false
            if (os || od || addition) return true
        }
        return false
    }

    const disableButtonNav = (disabledView: iView) => {
        switch (disabledView) {
            case 'needExplorer':
                return iViewStep[view] < iViewStep.needExplorer
            case 'prescription':
                return iViewStep[view] < iViewStep.prescription
            case 'demonstrations':
                return iViewStep[view] < iViewStep.demonstrations
            case 'conclusion':
                return iViewStep[view] < iViewStep.conclusion
            default:
                return false
        }
    }

    // Reserved in case they want the settings to be reverted back
    // const disableButtonNav = (disabledView:iView) => {
    //     switch (disabledView) {
    //         case 'needExplorer':
    //             return false
    //         case 'prescription':
    //             return needExplorerHasSelectedValue
    //         case 'demonstrations':
    //             return demonstrationViewSettings()
    //         case 'conclusion':
    //             return !(view === 'demonstrations' || view === 'conclusion')
    //         default:
    //             return false
    //     }
    // }


    // const demonstrationViewSettings = () => {
    //     if (view === 'intro') {
    //         return disableNext('needExplorer')
    //     }
    //     if (view === 'needExplorer') {
    //         return true
    //     }
    //     if (view === 'prescription') {
    //         return disableNext('prescription')
    //     }
    //     return false
    // }

    return {
        disableNext,
        disableButtonNav
    }
}

export default useVariluxDisableButton;