import React from 'react';
import useBEM from '../../utils/hooks/useBEM';
import ReactDOM from 'react-dom';


interface Props {
    show: boolean,
    message: string,
    buttons?: {
        label: string,
        id: string
    }[],
    onButtonSelect: (buttonId: string) => void
}

const Alert : React.FC<Props>  = ({
    show,
    message,
    buttons,
    onButtonSelect
}) =>  {
    // VARIABLES
    const portal: any = document.getElementById("portal");
    const [B, E] = useBEM('alert');

    const onClickHandler = (id: string) => {
        onButtonSelect(id)
    }

    const render = () => (
        <div className={B(show ? 'show' : '')}>            
            <div className={E('container')}>
                <h1 className={E('message')}>
                    {message}
                </h1>
                <div className={E('buttons')}>
                    {
                        buttons && buttons.map( b => (
                            <button 
                                key={b.id}
                                className={E('button')} 
                                onClick={() => onClickHandler(b.id)}> 
                                {b.label} 
                            </button>
                        ))
                    }
                </div>
            </div>
        </div>
    )

    return ReactDOM.createPortal(render(),portal)
}

export default Alert;