import React from 'react';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    text?: string,
    color?: string
}

const Spinner : React.FC<Props>  = ({
    text,
    color = '#fff'
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('lds-spinner');

    // HOOKS

    // METHODS

    return (
        <div className={B()}>
            {
                new Array(12).fill('').map((o,i) => (
                    <div key={i} style={{background: color}}></div>
                ))
            }
            { text && <span style={{color}} className={E('progress')}>{text}</span> }
        </div>       
    )
}

export default Spinner;