import settings from "../constants/settings.constants";
import CATEGORY from "../constants/variluxG9/varilux-demo-categories";

const newAssetsURL = (url: string) => {
    return `${settings[settings.environment].newAssetsUrl}VariluxG9/demo/${url}`;
}

const variluxG9Videos = [
    {
        directory: CATEGORY.INSTANT,
        videos: [
            newAssetsURL(CATEGORY.INSTANT+"/01_GPS A.mp4"),
            newAssetsURL(CATEGORY.INSTANT+"/01_GPS B.mp4"),
            newAssetsURL(CATEGORY.INSTANT+"/01_GPS C.mp4"),
            newAssetsURL(CATEGORY.INSTANT+"/01_GPS D.mp4"),
        ],
    },
    {
        directory: CATEGORY.STABILIZED,
        videos: [
            newAssetsURL(CATEGORY.STABILIZED+"/02_STAIRS A.mp4"),
            newAssetsURL(CATEGORY.STABILIZED+"/02_STAIRS B.mp4"),
            newAssetsURL(CATEGORY.STABILIZED+"/02_STAIRS C.mp4"),
            newAssetsURL(CATEGORY.STABILIZED+"/02_STAIRS D.mp4"),
        ],
    },    
    {
        directory: CATEGORY.PANOROMIC,
        videos: [
            newAssetsURL(CATEGORY.PANOROMIC+"/03_TROTT A.mp4"),
            newAssetsURL(CATEGORY.PANOROMIC+"/03_TROTT B.mp4"),
            newAssetsURL(CATEGORY.PANOROMIC+"/03_TROTT C.mp4"),
            newAssetsURL(CATEGORY.PANOROMIC+"/03_TROTT D.mp4"),
        ],
    },
    {
        directory: CATEGORY.SHARP,
        videos: [
            newAssetsURL(CATEGORY.SHARP+"/04_LIVRE A.mp4"),
            newAssetsURL(CATEGORY.SHARP+"/04_LIVRE B.mp4"),
            newAssetsURL(CATEGORY.SHARP+"/04_LIVRE C.mp4"),
            newAssetsURL(CATEGORY.SHARP+"/04_LIVRE D.mp4"),
        ],
    },
    {
        directory: CATEGORY.EXTENDED,
        videos: [
            newAssetsURL(CATEGORY.EXTENDED+"/05_TABLETTE A.mp4"),
            newAssetsURL(CATEGORY.EXTENDED+"/05_TABLETTE B.mp4"),
            newAssetsURL(CATEGORY.EXTENDED+"/05_TABLETTE C.mp4"),
            newAssetsURL(CATEGORY.EXTENDED+"/05_TABLETTE D.mp4"),
        ],
    },
    {
        directory: 'PRESCRIPTIONS',
        videos:[
            newAssetsURL('PRESCRIPTIONS/varilux_prescription_video.mp4')
        ]
    }
];
export default variluxG9Videos;