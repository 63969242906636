import React from 'react'
import { useSelector } from 'react-redux'
// import { getApp } from '../../../store/slices/app.slice'
import useBEM from '../../../utils/hooks/useBEM'
import VarNavbarHeader from './VarNavbarHeader'
import VarNavbarBottom from './VarNavbarBottom'
import { getVariluxG9 } from '../../../store/slices/variluxG9.slice'


const VarNavbar:React.FC = () => {
    const [ B ] = useBEM('varilux-navbar')
    // const appStore = useSelector(getApp);
    const { prescriptionVideoPlay } = useSelector(getVariluxG9)

  return (
    <>
    {!prescriptionVideoPlay ?
      <div className={B()}>
        <VarNavbarHeader/>
        <VarNavbarBottom/>
      </div> 
      :
      null
    }
  </>

  )
}

export default VarNavbar