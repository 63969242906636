import Varilux_Matrix from "../../interface/varilux-matrix.interface"

const varilux_matrix: Varilux_Matrix = {
    none:{
        low_myope:{
            low_add:{
                extended: 0,
                stabilized: 0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            },
            mid_add:{
                extended: 0,
                stabilized:0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            },
            high_add:{
                extended: 0,
                stabilized:0,
                panoramic:0,
                instant:0,
                sharp:0
            },
        },

        high_myope:{
            low_add:{
                extended:0,
                stabilized:0,
                panoramic:0,
                instant:0,
                sharp:0
            },
            mid_add:{
                extended:0,
                stabilized:0,
                panoramic: 0,
                instant:0,
                sharp: 0
            },
            high_add:{
                extended:0,
                stabilized: 0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            }
        },

        emmetropic:{
            low_add:{
                extended: 0,
                stabilized:0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            },
            mid_add:{
                extended: 0,
                stabilized: 0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            },
            high_add:{
                extended: 0,
                stabilized: 0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            }
        },

        low_hyperope:{
            low_add:{
                extended: 0,
                stabilized: 0,
                panoramic:0,
                instant: 0,
                sharp: 0
            },
            mid_add:{
                extended: 0,
                stabilized: 0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            },
            high_add:{
                extended: 0,
                stabilized: 0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            }
        },

        high_hyperope:{
            low_add:{
                extended: 0,
                stabilized: 0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            },
            mid_add:{
                extended: 0,
                stabilized: 0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            },
            high_add:{
                extended:0,
                stabilized: 0,
                panoramic: 0,
                instant: 0,
                sharp: 0
            }
        }
    },
    standard_progressive_lens:{
        low_myope:{
            low_add:{
                extended: 16.20822441,
                stabilized: -9.691148378,
                panoramic: 14.53908896,
                instant: 33.42395783,
                sharp: 51.04476716
            },
            mid_add:{
                extended: 19.56282605,
                stabilized: -13.3467453,
                panoramic: 9.356287705,
                instant: 51.02839098,
                sharp: 31.12484054
            },
            high_add:{
                extended: 128.7359399,
                stabilized: 12.07546201,
                panoramic: 40.20968547,
                instant: 76.96636986,
                sharp: 77.53441455
            },
        },

        high_myope:{
            low_add:{
                extended: 23.99720463,
                stabilized: -2.256157772,
                panoramic: 43.16455305,
                instant: 71.28381089,
                sharp: 25.37321562
            },
            mid_add:{
                extended: 23.08481623,
                stabilized: -13.1488358,
                panoramic: 24.50292839,
                instant: 93.09243687,
                sharp: 7.205707975
            },
            high_add:{
                extended: 92.76927984,
                stabilized: 10.03277559,
                panoramic: 59.20645529,
                instant: 99.00165343,
                sharp: 57.29566296
            }
        },

        emmetropic:{
            low_add:{
                extended: 23.34015273,
                stabilized: 9.605824648,
                panoramic: 20.64439144,
                instant: 34.91307856,
                sharp: 79.92902121
            },
            mid_add:{
                extended: 40.37075,
                stabilized: 4.101040789,
                panoramic: 14.50159067,
                instant: 31.9559952,
                sharp: 88.78596267
            },
            high_add:{
                extended: 135.1965586,
                stabilized: 21.01504333,
                panoramic: 48.09454081,
                instant: 93.39544742,
                sharp: 81.17735109
            }
        },

        low_hyperope:{
            low_add:{
                extended: 9.776435274,
                stabilized: 36.99800833,
                panoramic: 2.144376633,
                instant: 32.81223152,
                sharp: 160.8634637
            },
            mid_add:{
                extended: 21.71472302,
                stabilized: 41.09935725,
                panoramic: 5.977806836,
                instant: 28.13384894,
                sharp: 196.0510463
            },
            high_add:{
                extended: 70.95507669,
                stabilized: 12.40237305,
                panoramic: 16.19457286,
                instant: 52.2754674,
                sharp: 220.1450768
            }
        },

        high_hyperope:{
            low_add:{
                extended: -1.028660068,
                stabilized: 40.73388597,
                panoramic: 27.15544958,
                instant: 42.80880495,
                sharp: 87.40937178
            },
            mid_add:{
                extended: 8.683374204,
                stabilized: 47.45031677,
                panoramic: 39.90977245,
                instant: 40.27104252,
                sharp: 120.4507311
            },
            high_add:{
                extended: 30.96186004,
                stabilized: 17.23292186,
                panoramic: 39.98336997,
                instant: 44.33864805,
                sharp: 177.0129851
            }
        }
    },



    varilux_comfort:{
        low_myope:{
            low_add:{
                extended: 5.448985739,
                stabilized: 10.94952304,
                panoramic: 21.91285489,
                instant: 31.72980159,
                sharp: -5.371737417
            },
            mid_add:{
                extended: 4.595889293,
                stabilized: 12.64696123,
                panoramic: 35.07687963,
                instant: 41.02797777,
                sharp: -24.26750402
            },
            high_add:{
                extended: 16.56709117 ,
                stabilized: 28.50893927,
                panoramic: 56.21685447,
                instant: 60.54236944,
                sharp: -44.11983973
            }
        },

        high_myope:{
            low_add:{
                extended: 5.677038491,
                stabilized: 4.498251397,
                panoramic: 56.710483,
                instant: 48.05549108,
                sharp: -2.873110538
            },
            mid_add:{
                extended: 1.352374594,
                stabilized: 0.002154102,
                panoramic: 56.58118876,
                instant: 61.73542537,
                sharp: -20.8972008
            },
            high_add:{
                extended: 4.512768078,
                stabilized: 23.08171912,
                panoramic: 69.4862372,
                instant: 71.43196195,
                sharp: -48.04903585
            },
        },
        
        emmetropic:{
            low_add:{
                extended: 18.2919,
                stabilized: 23.91213614,
                panoramic: 43.05809833,
                instant: 42.62188655,
                sharp: 15.25837191
            },
            mid_add:{
                extended: 27.31846279,
                stabilized: 17.73911997,
                panoramic: 33.76485756,
                instant: 60.60317366,
                sharp: 17.93315676
            },
            high_add:{
                extended: 70.76921429,
                stabilized: 25.26914814,
                panoramic: 50.39139069,
                instant: 72.13168102,
                sharp: 15.04005366
            },
        },

        low_hyperope:{
            low_add:{
                extended: -0.528463019,
                stabilized: 36.3068722,
                panoramic: 33.04435987,
                instant: 48.71838875,
                sharp: 15.28430763
            },
            mid_add:{
                extended: 1.613130955,
                stabilized: 34.49939026,
                panoramic: 31.65944611,
                instant: 58.02086282,
                sharp: 12.71549122
            },
            high_add:{
                extended: 58.24191959,
                stabilized: 31.58201447,
                panoramic: 54.419417,
                instant: 64.83808459,
                sharp: 18.631578
            },
        },
        
        high_hyperope:{
            low_add:{
                extended: -5.135450625,
                stabilized: 30.95434037,
                panoramic: 76.16475532,
                instant: 47.77466953,
                sharp: 8.977145782
            },
            mid_add:{
                extended: -1.650745733,
                stabilized: 33.89650862,
                panoramic: 78.40382419,
                instant: 51.68105585,
                sharp: 9.318518358
            },
            high_add:{
                extended: 49.79208816,
                stabilized: 22.9179387,
                panoramic: 98.57813184,
                instant: 49.64507721,
                sharp: 19.45858675
            },
        },
    },


    varilux_x_series:{
        low_myope:{
            low_add:{
                extended: 1.762456715,
                stabilized: -17.47041642,
                panoramic: 10.460397,
                instant: 33.26781724,
                sharp: -7.066752853
            },
            mid_add:{
                extended: 4.014844902,
                stabilized: -29.34210938,
                panoramic: 28.28255067,
                instant: 61.89806629,
                sharp: -9.229877288
            },
            high_add:{
                extended: 3.720213688 ,
                stabilized: -3.907034721,
                panoramic: 33.32259589,
                instant: 53.99987003,
                sharp: -20.41589159
            }
        },

        high_myope:{
            low_add:{
                extended: 6.099394556,
                stabilized: -11.24397668,
                panoramic: 29.6509863,
                instant: 57.02026616,
                sharp: 1.741881791
            },
            mid_add:{
                extended: 11.4398299,
                stabilized: -21.44558221,
                panoramic: 69.28692902,
                instant: 106.2937577,
                sharp: -0.75424116
            },
            high_add:{
                extended: 9.713957086,
                stabilized: -4.483715819,
                panoramic: 58.43883824,
                instant: 72.02194374,
                sharp: -30.39756185
            }
        },

        emmetropic:{
            low_add:{
                extended: 2.254333354,
                stabilized: 8.61607726,
                panoramic: 25.27093007,
                instant: 8.606746637,
                sharp: -9.220858173
            },
            mid_add:{
                extended: 4.021798197,
                stabilized: 4.830478171,
                panoramic: 8.286340852,
                instant: 15.94996171,
                sharp: -7.404415363
            },
            high_add:{
                extended: 3.664538618,
                stabilized: 10.14444396,
                panoramic: 43.96047877,
                instant: 37.62403775,
                sharp: 1.301071358
            }
        },

        low_hyperope:{
            low_add:{
                extended: 0.90454517,
                stabilized: 16.73918951,
                panoramic: 29.25364798,
                instant: 18.57816417,
                sharp: -0.249089001
            },
            mid_add:{
                extended: 1.102349803,
                stabilized: 25.58310527,
                panoramic: 56.62228035,
                instant: 44.33703217,
                sharp: 5.782054932
            },
            high_add:{
                extended: 1.917567989,
                stabilized: 21.72325639,
                panoramic: 64.39004814,
                instant: 40.42320497,
                sharp: 5.206929909
            }
        },

        high_hyperope:{
            low_add:{
                extended: 5.602589971,
                stabilized: 15.3178461,
                panoramic: 85.10425063,
                instant: 34.55545842,
                sharp: -1.208388588
            },
            mid_add:{
                extended: 13.45117325,
                stabilized: 18.73217194,
                panoramic: 159.7095417,
                instant: 74.10811795,
                sharp: 1.242280466
            },
            high_add:{
                extended: 17.18117834,
                stabilized: 16.39429037,
                panoramic: 82.00828039,
                instant: 44.91682032,
                sharp: 15.0638362
            }
        },
    },


    varilux_physio_30:{
        low_myope:{
            low_add:{
                extended: 21.70113445,
                stabilized: -18.55069159,
                panoramic: 20.00408626,
                instant: 51.18732709,
                sharp: 3.246305651
            },
            mid_add:{
                extended: 32.55069424,
                stabilized: -20.04172913,
                panoramic: 25.16814333,
                instant: 67.75418439,
                sharp: -9.726443156
            },
            high_add:{
                extended: 74.4040218,
                stabilized: 5.038748671,
                panoramic: 22.15400087,
                instant: 60.07402951,
                sharp: -20.13190123
            }
        },

        high_myope:{
            low_add:{
                extended: 31.6388818,
                stabilized: -10.41030539,
                panoramic: 66.11487844,
                instant: 86.09175118,
                sharp: 15.62564294
            },
            mid_add:{
                extended: 42.86221774,
                stabilized: -23.37203384,
                panoramic: 65.96216617,
                instant: 104.0356712,
                sharp: 8.034370727
            },
            high_add:{
                extended: 71.89196383,
                stabilized: -1.268382962,
                panoramic: 46.43180918,
                instant: 78.28778891,
                sharp: -13.29860337
            }
        },

        emmetropic:{
            low_add:{
                extended: 7.98676395,
                stabilized: 13.77940479,
                panoramic: 23.38712871,
                instant: 14.3097305,
                sharp: -9.193546927
            },
            mid_add:{
                extended: 11.79925257,
                stabilized: 7.514958008,
                panoramic: -2.87627551,
                instant: 19.50952866,
                sharp: -11.2657667
            },
            high_add:{
                extended: 16.61305236,
                stabilized: 15.24441152,
                panoramic: 38.42455456,
                instant: 42.20645598,
                sharp: -13.47559895
            }
        },

        low_hyperope:{
            low_add:{
                extended: -1.534937081,
                stabilized: 33.22805453,
                panoramic: 68.99992466,
                instant: 40.44655422,
                sharp: -6.15207302
            },
            mid_add:{
                extended: 1.183407234,
                stabilized: 40.30873221,
                panoramic: 69.41925251,
                instant: 40.02471407,
                sharp: -7.41187986
            },
            high_add:{
                extended: 7.897521831,
                stabilized: 25.94484157,
                panoramic: 13.50543269,
                instant: 41.9286635,
                sharp: 10.38266078
            }
        },

        high_hyperope:{
            low_add:{
                extended: -0.965997159,
                stabilized: 38.99493466,
                panoramic: 95.30958285,
                instant: 55.82046703,
                sharp: -3.570450491
            },
            mid_add:{
                extended: 11.53119853,
                stabilized: 43.54463727,
                panoramic: 115.7199961,
                instant: 59.85084979,
                sharp: -2.444374378
            },
            high_add:{
                extended: 18.80136927,
                stabilized: 21.2156491,
                panoramic: 49.50962627,
                instant: 44.61326733,
                sharp: 18.6291229
            }
        },
    },
}


export default varilux_matrix