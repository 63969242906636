import settings from "./settings.constants";

const apiURL = settings[settings.environment].apiUrl;
const withVersion = settings.environment !== 'prod' ? 'api/v1/' : '';
const apiUrlWithVersion = `${apiURL}${withVersion}`;
const {
    azureApiUrl,
    azureApiCodes
} = settings[settings.environment];

const ENDPOINTS = {
    activateLicense: `${apiURL}bearertoken`, //register
    account: `${apiUrlWithVersion}account`,
    translation: `${apiUrlWithVersion}translation`,
    email: `${apiUrlWithVersion}email`,

    // TEMP

    lensThickness: `${azureApiUrl}lensthickness/calculate?code=${azureApiCodes[0]}`,
    eprom: (region: string, country: string) => `${azureApiUrl}eproms?code=${azureApiCodes[1]}&region=${region}&country=${country}`,
    // emats: `https://cpbx-dev-app-frc-03-companion.azurewebsites.net/api/v1/emats`,
    // whatsnew: `https://cpbx-dev-app-frc-03-companion.azurewebsites.net/api/v1/whatsnew`,
    emats: `${apiUrlWithVersion}emats`,
    whatsnew: `${apiUrlWithVersion}whatsnew`,
    tutorial: `${apiUrlWithVersion}tutorial`,
    products: `${apiUrlWithVersion}products`,
    productCombi: `${apiUrlWithVersion}pricecombination`,
    masterLicenseData: `${azureApiUrl}config?code=${azureApiCodes[0]}`,
    orders: `${azureApiUrl}orders?code=${azureApiCodes[0]}`,
    order: (id: string) => `${azureApiUrl}orders/${id}?code=${azureApiCodes[0]}`,
    frametraces: (countryCode: string, shopId:string) => `${azureApiUrl}frametrace?countryShopId=${countryCode}-${shopId}&code=${azureApiCodes[2]}`,
    frametrace: (id: string) => `${azureApiUrl}frametrace/${id}?code=${azureApiCodes[3]}`
}

export default ENDPOINTS;
