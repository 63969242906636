import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KIDS_THEMES } from '../../constants/theme.constants';
import {TRANSLATIONS_DEFAULT} from '../../constants/translations.contants';
import iGlobalInformation from '../../interface/global-information';
import iTheme from '../../interface/theme.interface';
import iTranslation from '../../interface/translation.interface';
import iAppStore from '../interface/app-store.interface';
import iRootState from '../interface/root-state.interface';
import { languageCode } from '../../constants/language-code.constants';
import iRegion from '../../interface/region.interface';
import iCountry from '../../interface/country.interface';
import ExperienceType from '../../types/experience.type';
import iG9CountryLanguages from '../../interface/g9-country-languages.interface';
import iG9Languages from '../../interface/g9-languages.interface';

const initialState: iAppStore  = {
    g9CountryLanguages: [],
    g9Languages: [],
    dataStructureVersion:0,
    loading: {
        show: false
    },
    experience: ExperienceType.VARILUX,
    theme: KIDS_THEMES[1],
    language: 'EN',
    subLanguage: '',
    regions: [],
    translation: TRANSLATIONS_DEFAULT.enUS,

    globalInformation: {
        storeName: '',
        phone: '',
        address: '',
        email: '',
        license: '',
        nickname: ''
    },
    globalAlert: {
        show: false,
        message: ''
    },
    isModalOpen:false
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        loading: (state, action: PayloadAction<boolean | {
            show: boolean,
            text?: string,
            textOnly?: boolean
        }>) => {
            if(typeof action.payload === 'boolean'){
                return {
                    ...state,
                    loading: {
                        show: action.payload,
                    }
                }
            }

            return {
                ...state,
                loading: action.payload
            }

        },
        changeTheme: (state, action: PayloadAction<iTheme>) => ({...state, theme: action.payload }),
        showGlobalAlert: (state, action: PayloadAction<{
            show: boolean,
            message?: string,
            action?: () => void
        }>) => ({...state, globalAlert: action.payload }),       
        setLanguage: (state, action: PayloadAction<string>) => ({ ...state, language: action.payload }),
        setSubLanguage: (state, action: PayloadAction<string>) => ({ ...state, subLanguage: action.payload }),
        setToken: (state, action: PayloadAction<string>) => ({ ...state, token: action.payload }),
        saveRegions: (state, action: PayloadAction<iRegion[]>) => ({ ...state, regions: action.payload }),
        setTranslationRegion: (state, action: PayloadAction<iRegion>) => ({ ...state, translationRegion: action.payload }),
        setTranslationCountry: (state, action: PayloadAction<iCountry>) => ({ ...state, translationCountry: action.payload }),
        setTranslationCountryLanguage: (state, action: PayloadAction<{
            country: string,
            languageCode: string
        } | undefined >) => ({ ...state, translationCountryLanguage: action.payload }),        
        // setTranslations: (state, action: PayloadAction<{[key: string]: iTranslation}>) => ({ ...state, translations: action.payload }),
        setTranslations: (state, action: PayloadAction<{[key: string]: iTranslation}>) => {
            let o = action.payload;
            let sorted:any = {},
            key:any, store:any = [],
            storeKey:any = [];
            o = {
                ...action.payload,
                ...languageCode
            }


            for (key in o) {
                if (o.hasOwnProperty(key)) {
                    store.push(o[key]);
                    storeKey.push(key);
                }
            }
            store.sort( function( firstSort:any, secondSort:any ) {
                firstSort = firstSort.country.toLowerCase();
                secondSort= secondSort.country.toLowerCase();
                return firstSort < secondSort ? -1 : firstSort > secondSort ? 1 : 0;
            });
            storeKey.sort();
            store.map((key:any, i:number)=>{
                sorted[key.code] = key;
                return true;
            })

            return {
                ...state, translations: sorted
            }
        },
        setTranslation: (state, action: PayloadAction<iTranslation>) => ({
            ...state,
            translation: action.payload
        }),       
        
        setG9CountryLanguages: (state, action: PayloadAction<iG9CountryLanguages[]>) => {
            return {
                ...state,
                g9CountryLanguages: action.payload
            }
        },

        setG9Languages: (state, action: PayloadAction<iG9Languages[]>) => {
            return {
                ...state,
                g9Languages: action.payload
            }
        },

        saveGlobalInformation: (state, action: PayloadAction<iGlobalInformation>) => ({ ...state, globalInformation: action.payload }),
        
        
        selectedLanguage: (state, action: PayloadAction<string>) => ({
            ...state,
        }),
        
        updateDataStructureVersion: (state, action: PayloadAction<number>) => ({
            ...state,
            dataStructureVersion: action.payload
        }),
        resetData: (state) => ({
            ...state,
            globalAlert: {
                show:false
            },
            translations: undefined,
        }),
        setIsModalOpen: (state, action: PayloadAction<boolean>) => ({
            ...state,
            isModalOpen: action.payload
        }),
    }
});

export const getApp = (state:iRootState) => state.app;
export default appSlice;
