const PRESCRIPTION_RULES = {
    standard: {
        sphere: {
            increment: 0.25,
            min: -20.00,
            max: 20.00,
            validation: {
                required: true,
                requiredSign: true
            }
        },
        cylinder: {
            increment: 0.25,
            min: -6,
            max: 6,
            validation: {
                required: false,
                requiredSign: true
            }
        },
        axis: {
            increment: 1,
            min: 0,
            max: 180,
            suffix: '°',
            validation: {
                required: false,
                requiredSign: false,
            }
        },
        addition: {
            increment: 0.25,
            min: 0.50,
            max: 4.00,
            validation: {
                required: false,
                requiredSign: true
            }
        }
    },
    ava: {
        sphere: {
            increment: 0,
            min: -20.00,
            max: 20.00,
            validation: {
                required: true,
                requiredSign: true
            }
        },
        cylinder: {
            increment: 0,
            min: -6,
            max: 6,
            validation: {
                required: false,
                requiredSign: true
            }
        },
        axis: {
            increment: 1,
            min: 0,
            max: 180,
            suffix: '°',
            validation: {
                required: false,
                requiredSign: false
            }
        },
        addition: {
            increment: 0.25,
            min: 0.50,
            max: 4.00,
            validation: {
                required: false,
                requiredSign: true
            }
        }
    }
}
const PRESCRIPTION_RULES_VARILUX = {
    standard: {
        sphere: {
            increment: 0.25,
            min: -20.00,
            max: 20.00,
            validation: {
                required: true,
                requiredSign: true
            }
        },
        cylinder: {
            increment: 0.25,
            min: -6,
            max: 6,
            validation: {
                required: false,
                requiredSign: true
            }
        },
        axis: {
            increment: 1,
            min: 0,
            max: 180,
            suffix: '°',
            validation: {
                required: false,
                requiredSign: false,
            }
        },
        addition: {
            increment: 0.25,
            min: 0.50,
            max: 4.00,
            validation: {
                required: true,
                requiredSign: true
            }
        }
    },
    ava: {
        sphere: {
            increment: 0,
            min: -20.00,
            max: 20.00,
            validation: {
                required: true,
                requiredSign: true
            }
        },
        cylinder: {
            increment: 0,
            min: -6,
            max: 6,
            validation: {
                required: false,
                requiredSign: true
            }
        },
        axis: {
            increment: 1,
            min: 0,
            max: 180,
            suffix: '°',
            validation: {
                required: false,
                requiredSign: false
            }
        },
        addition: {
            increment: 0.25,
            min: 0.50,
            max: 4.00,
            validation: {
                required: false,
                requiredSign: true
            }
        }
    }
}



const PRESCRIPTION_FORM = [
    {
        label: 'prescription_rx_sphere',
        name: 'sphere',
        fields: [
            {
                position: 'od',
                label: 'rx_righ_teye'
            },
            {
                position: 'os',
                label: 'rx_left_eye'
            }
        ]
    },
    {
        label: 'prescription_rx_cylinder_',
        name: 'cylinder',
        fields: [
            {
                position: 'od',
                label: 'rx_righ_teye'
            },
            {
                position: 'os',
                label: 'rx_left_eye'
            }
        ]
    },
    {
        label: 'prescription_rx_axis_',
        name: 'axis',
        fields: [
            {
                position: 'od',
                label: 'rx_righ_teye'
            },
            {
                position: 'os',
                label: 'rx_left_eye'
            }
        ]
    },
    {
        label: 'prescription_rx_addition_',
        name: 'addition',
        fields: [
            {}
        ]
    }
]
const PRESCRIPTION_FORM_VARILUX = [
    {
        label: 'prescription_rx_sphere',
        name: 'sphere',
        fields: [
            {
                position: 'od',
                label: 'rx_righ_teye'
            },
            {
                position: 'os',
                label: 'rx_left_eye'
            }
        ]
    },
    {
        label: 'prescription_rx_addition_',
        name: 'addition',
        fields: [
            {}
        ]
    }
]

export {
    PRESCRIPTION_FORM,
    // NO_RX_DATA,
    // PRESCRIPTION_LENS_DATA,
    // PRESCRIPTION_SUNWEAR_DATA,
    PRESCRIPTION_FORM_VARILUX,
    PRESCRIPTION_RULES_VARILUX,
}
export default PRESCRIPTION_RULES;
