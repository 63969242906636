import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getVariluxG9 } from '../../../store/slices/variluxG9.slice'
import useBEM from '../../../utils/hooks/useBEM'

interface Props {
}

export enum iViewStep {
  intro = 0,
  needExplorer,
  prescription,
  demonstrations,
  conclusion
}
const ProgressBar:React.FC<Props> = () => {
    const [B, E] = useBEM('varilux-progress')
    const [multiplier, setMultiplier] = useState<number>(1)
    const width:number = multiplier * 25
    const {view } = useSelector(getVariluxG9)



    useEffect(() => {
      setMultiplier(iViewStep[view])
    }, [view])
    

  return (
    <div className={B()}>
      <div className={E('label')}>
        {`${width}%`}
      </div>

      <div className={E('bar')}>
        <span style={{width:`${width}%`}} className={E('bar-percent')}></span>
      </div>
    </div>
  )
}

export default ProgressBar