import iAppStore from "../../store/interface/app-store.interface";
import axios from 'axios';
import ENDPOINTS from "../../constants/endpoints";

type hookType = {
    t: (key: string, fallback?: string) => string,
    getTranslations: (params: {
        onSuccess?: (res: any) => void,
        onError?: (err:any) => void,
    }) => void,

    getTranslations2: (region: string, loading?: boolean) => Promise<{
        status: 'success' | 'error',
        responseData: any
    }>,
    sortCountries: (data: any) => any,
    generateIconImage: (country: string) => string

}

const useTranslation  = (app: iAppStore): hookType =>  {

        const translate = (key: string, fallback?: string): string => {  
            if(!app.translation)return key;
            //const multipleLanguages = ["caCA", "chCH", "beBE"];
            const lang = app.translation;
            if(!lang || !lang?.translation)return key;
            const text = lang.translation[key];
            return text || fallback || key;
        }

        const getTranslations = (params: {
            onSuccess?: (res: any) => void,
            onError?: (err:any) => void,
        }) => {
            const {
                onSuccess,
                onError
            } = params;

            axios.get(ENDPOINTS.translation, { headers: {Authorization : true}})
            .then((res) => onSuccess && onSuccess(res))
            .catch((err) => onError && onError(err))
        }

        const getTranslations2 = (region: string, loading = false): Promise<{
            status: 'success' | 'error',
            responseData: any
        }> => {           
            const promise = new Promise<{
                status: 'success' | 'error',
                responseData: any
            }>((res, rej) => {
                axios.get(`${ENDPOINTS.translation}/${region || ''}`, { headers: {
                    Authorization : true,
                    background: !loading
                }})
                .then((resp) => res({
                    status: 'success',
                    responseData: resp
                }))
                .catch((err) => rej({
                    status: 'error',
                    responseData: err
                }))
            });
            return promise;
        }

        const sorter = ( a: string, b: string ): number => {
            const aa = a.toLocaleLowerCase();
            const bb = b.toLocaleLowerCase();
            if ( aa < bb )return -1;        
            if ( aa > bb )return 1;
            return 0;
        }
    
        const sortCountries = (data: any) => {
            const entries = Object.entries(data);
            return entries.sort((a: any,b: any) => {
                return sorter(a[1].label, b[1].label)
            })
        }

        const generateIconImage = (countryName: string) => {
            return (countryName || '').toLocaleLowerCase().replace(/ /g,'_');
        }

        return {
            t: translate,
            getTranslations,
            getTranslations2,
            sortCountries,
            generateIconImage
        }
}

export default useTranslation;