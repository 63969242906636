import React, { useEffect, useRef, useState } from 'react';
import useBEM from '../../../utils/hooks/useBEM';
import Utils from '../../../utils/Utils';
import LoadingAssets from '../../../components/ui/loadingAssets';
import { IndexedDBContext, IndexedDBContextType } from '../../../utils/context/indexedDBContext';
import variluxG9Videos from '../../../utils/varilux-g9-videos';
interface Props {

}
const DownloadVariluxAssets: React.FC<Props> = () => {
    // VARIABLES
    const [ B ] = useBEM('download-assets');
    const { downloadAssetBase64 } = Utils();
    const [preloadComplete, setPreloadComplete] = useState(false);
    const { get, insert } = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const [doneCount, setDoneCount] = useState(0);
    const loadedCount = useRef(0);
    const totalAssets = 21;

    // HOOKS
    useEffect(() => {
        downloadAssets();
        return () => { };
    }, []);
    const loaded = () => {
        loadedCount.current += 1;
        setDoneCount(prev => prev + 1);
        if (loadedCount.current >= totalAssets) {
            setPreloadComplete(true);
        }
    }
    const downloadPercentage = () => {
        const percent = (doneCount / totalAssets * 100);
        // return  `${ Math.floor(percent) }%`;
        return Math.floor(percent);
    }
    const downloadAssets = async () => {
        for (const category of variluxG9Videos) {
            category?.videos?.map(async (asset, i) => {
                const isAlreadyDownloaded = await isAlreadyPreloaded(category.directory, i);

                if (!isAlreadyDownloaded) {
                    const base64 = await downloadAssetBase64(asset)
                    await insert('variluxG9Store', {
                        id: `${category.directory}_${i}`,
                        asset: base64,
                    });
                    loaded();
                } else {
                    loaded();
                }
            })
        }
    }
    const isAlreadyPreloaded = async (category: string, i: number) => {
        const id = `${category}_${i}`;
        const asset = await get('variluxG9Store', id);
        return asset ? true : false;
    }
    return (
        <div className={B(preloadComplete ? 'complete' : '')}>
            <LoadingAssets percentage={downloadPercentage()} use='varilux'/>
        </div>
    );
}

export default DownloadVariluxAssets;