import iVariluxProductCard, { VariluxPreviewType } from '../interface/varilux-product-card.interface';
import variluxCategories from '../constants/variluxG9/varilux-categories';
import Image1 from '../assets/images/demonstrations/01.webp';
import Image2 from '../assets/images/demonstrations/02.webp';
import Image3 from '../assets/images/demonstrations/03.webp';
import Image4 from '../assets/images/demonstrations/04.webp';
import Image5 from '../assets/images/demonstrations/05.webp';

const variluxProducts: iVariluxProductCard[] = [
  {
    category: variluxCategories.INSTANT,
    image: Image1,
    title: ['varilux_demo_card_instant','Instant sharpness at all distances, even in motion'],
    isRecommended: false,
    isExclusive: true,
    previewItems: {
      type: VariluxPreviewType.VIDEO,
      list: [{
        title: "Standard progressive lens",
        source: ""
      }, {
        title: "Varilux® XR series™",
        source: ""
      }],
    },
    video: "",
  },
  {
    category: variluxCategories.STABILIZED,
    image: Image2,
    title: ['varilux_demo_card_stabilized','Stabilized vision even in the most demanding situations'],
    isRecommended: true,
    isExclusive: false,
    previewItems: {
      type: VariluxPreviewType.VIDEO,
      list: [{
        title: "Standard progressive lens",
        source: ""
      }, {
        title: "Varilux® XR series™",
        source: ""
      }],
    },
    video: "",
  },
  {
    category: variluxCategories.PANORAMIC,
    image: Image3,
    title: ['varilux_demo_card_panoramic',"Panoramic field of vision"],
    isRecommended: false,
    isExclusive: false,
    previewItems: {
      type: VariluxPreviewType.VIDEO,
      list: [{
        title: "Standard progressive lens",
        source: ""
      }, {
        title: "Varilux® XR series™",
        source: ""
      }],
    },
    video: "",
  },
  {
    category: variluxCategories.SHARP,
    image: Image4,
    title: ['varilux_demo_card_sharp',"High visual acuity even in low light conditions"],
    isRecommended: false,
    isExclusive: false,
    previewItems: {
      type: VariluxPreviewType.VIDEO,
      list: [{
        title: "Standard progressive lens",
        source: ""
      }, {
        title: "Varilux® XR series™",
        source: ""
      }],
    },
    video: "",
  },
  {
    category: variluxCategories.EXTENDED,
    image: Image5,
    title: ['varilux_demo_card_extended',"Natural posture in all circumstances"],
    isRecommended: false,
    isExclusive: false,
    previewItems: {
      type: VariluxPreviewType.VIDEO,
      list: [{
        title: "Standard progressive lens",
        source: ""
      }, {
        title: "Varilux® XR series™",
        source: ""
      }],
    },
    video: "",
  },
];

export default variluxProducts;