const variluxCategories = {
    EXTENDED: "extended",
    INSTANT: "instant",
    PANORAMIC: "panoramic",
    SHARP: "sharp",
    STABILIZED: "stabilized",
};
export default variluxCategories;



