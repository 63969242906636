import iVariluxSteps from "../../interface/variluxSteps.interface";


export const variluxSteps:iVariluxSteps[] = [
    {
        id:'needExplorer',
        label:['lens_proposal_builder_lpb_need_explorer', 'Need Explorer']
    },
    {
        id:'prescription',
        label:['current_lens_rx_current_lens', 'Current Lenses']
    },
    {
        id:'demonstrations',
        label:['legal_notice_ln_01_demonstrations_title', 'Demonstration']
    },
    {
        id:'conclusion',
        label:['conclusion_rx_conclusion', 'Conclusion']
    },
]