import React from 'react';
import useBEM from '../../../utils/hooks/useBEM';
import { IconType } from './IconType';
import { SvgIcons } from './svg-icon-list';
import ReactHtmlParser from 'react-html-parser';

interface Props {
    className?: string,
    name: IconType,
    color?: string,
    width?: string,
    height?: string,
    circle?: boolean,
    bordered?: boolean,
    backgroundColor?: string,
    borderColor?: string,
    html?: boolean,
    withViewBox?: boolean,
    padding?: string,

    onClick?: () => void
}

const SvgIcon : React.FC<Props>  = ({
    className = '',
    name,
    color,
    width,
    height,
    circle = false,
    backgroundColor = 'transparent',
    borderColor,
    bordered,
    html = false,
    withViewBox = true,
    padding = '0rem',
    onClick
}) =>  {

    const [B ,E] = useBEM('svg-icon');

    const cssProperties = () => {
        return {
            '--app-svg-icon-fill': color,
            '--app-svg-icon-height': height || width || '1.5rem',
            '--app-svg-icon-width': width || height || '1.5rem',
            '--app-svg-icon-background-color': backgroundColor,
            '--app-svg-icon-border-color': borderColor,
            '--app-svg-icon-padding': padding
        } as React.CSSProperties
    }

    const modfiers = () => {
        const mods = [];
        if(circle)mods.push('circle');
        if(bordered)mods.push('bordered');

        return mods;
    }

    const onClickHandler = () => {
        if(onClick)onClick()
    }

    return (
        <div 
            className={`${B(modfiers())} ${className}`} 
            style={cssProperties()}        
            onClick={onClickHandler}
        >
            <svg 
                className={E('svg')}
                focusable="false" 
                viewBox={ withViewBox ? '0 0 24 24' : ''} 
            >   
                { !html && <path d={SvgIcons[name]} /> }

                { html && (
                    ReactHtmlParser(SvgIcons[name])
                )}

            </svg>
        </div>
    );
}

export default SvgIcon;