// import { backgroundPosition } from 'html2canvas/dist/types/css/property-descriptors/background-position';
// import { backgroundSize } from 'html2canvas/dist/types/css/property-descriptors/background-size';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
    name: string,
    type?: 'svg' | 'image' | 'png' | 'gif',
    extension?: 'png' | 'jpg' | 'gif',
    className?: string,
    click?: () => void,
    background?: boolean,
    customStyle?: any,
    position?: string

    // for gtm id
    gtmId?: string
}

const Icon : React.FC<Props>  = ({
        name,
        type = 'svg',
        extension = 'png',
        className,
        click,
        background = false,
        customStyle,
        position = 'top',
        gtmId
    }) =>  {
    // VARIABLES
    const [ image, setImage ] = useState();
    const folders = {
        svg: 'svg',
        image: 'images',
        png: 'png',
        gif: 'gif'
    }

    // METHODS
    const getFileExtension = () => {
        return type === 'svg' ? 'svg' : extension;
    }


    const onClickHandler = () => {
        if(click)click();
    }

    const renderImage = () => {
        if(background) {
            return <div
                style={
                    {
                        backgroundImage: `${className === 'box-bg' ? '' : 'linear-gradient(to bottom, rgba(0,0,0,0) 20%, rgba(0,0,0,0.5)),'} url(${image})`,
                        backgroundPosition: `${position}`,
                        backgroundSize: `${position === 'center' ? 'cover' : '100%'}`
                    }
                }
                className={className}
                onClick={onClickHandler}>
            </div>;
        }
        return <img src={image} id={gtmId} alt="" className={className} onClick={onClickHandler} style={customStyle}/>
    }

    // HOOKS
    const mounting = useRef(false)
    useEffect(() => {
        mounting.current = true
        const loadImage = (imgName: string) => {
            const ext = getFileExtension();
            import(`../../assets/${folders[type]}/${imgName}.${ext}`).then(img => {
              if(mounting.current){
                setImage(img.default)
                };
            }).catch(()=> console.log(`Image not found: ${imgName}`));
        };
        loadImage(name);

        return () => {
            mounting.current = false
        }
    }, [name])

    return (
        <>
            { image && renderImage()}
        </>
    );
}

export default Icon;
