import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import variluxChoices from '../../constants/variluxG9/need-explorer-choices';
import IVariluxResult from '../../interface/varilux-matrix-result.interface';
import PrescriptionAddSegmentationType from '../../types/prescription-add-segmentation-varilux.type';
import PrescriptionEyeConditionType from '../../types/prescription-eye-condition-varilux.type';
import PrescriptionProductTypeVarilux from '../../types/prescription-product-varilux.type';
import iRootState from '../interface/root-state.interface';
import iVariluxG9, { iView } from '../interface/variluxG9.interface';


const initialState: iVariluxG9  = {
    view: 'intro',
    needExplorerChoices:variluxChoices,
    prescriptionVarilux: {
        productSelected: PrescriptionProductTypeVarilux.NONE,
        values: {
            sphere: { od: undefined, os: undefined },
            addition: undefined
        },
        sphereMean:undefined,
        addition:undefined,
    },
    ametropia: null,
    additionSegmentation:null,
    categoryPercentages:null,
    lensCategorySelected:false,
    showDownloadPopup:false,
    prescriptionVideoPlay:false,
}

const variluxgG9Slice = createSlice({
    name: 'variluxG9',
    initialState,
    reducers: {
        changeView: (state, action: PayloadAction<iView>) => ( {...state, view:action.payload} ),
        selectChoice: (state, action: PayloadAction<number>) => {
            const newChoices = [...state.needExplorerChoices.map(choice => {

                // deselect none if others are selected
                if(choice.id === 6 && action.payload !== 6){
                    return {
                        ...choice,
                        selected: false
                    }
                }

                // deselect others if none is selected.
                if(choice.id !== 6 && action.payload ===6){
                    return {
                        ...choice,
                        selected:false
                    }
                }

                // otherwise select / deselect
                if(choice.id === action.payload){
                    return {
                        ...choice,
                        selected:!choice.selected
                    }
                }
                return choice
            })]


            return {
                ...state,
                needExplorerChoices:newChoices
            }
        },
        savePrescription:(state, action: PayloadAction<any>) => {
            const {sphere, addition} = action.payload

            const convertValuesToNumber = (value?:string) => {
                const isPositive = value?.[0] === '+'
                const number = value && value.length > 2 && value.slice(1)
                let convertedString:number;
                if(number){
                    convertedString = isPositive ? +number : -number
                    return convertedString
                } 
                return undefined
            }
            const os = convertValuesToNumber(sphere?.os)
            const od = convertValuesToNumber(sphere?.od)
            const add = convertValuesToNumber(addition)

            return {
                ...state,
                prescriptionVarilux: {
                    ...state.prescriptionVarilux,
                    values:{
                        ...action.payload
                    },
                    sphereMean: (os && od) && (od + os)/2,
                    addition: add && add,

                }
            }
        },
        selectProduct:(state, action: PayloadAction<PrescriptionProductTypeVarilux>) => {
            // reset values to initial state if selected product is None
            return {
                ...state,
                prescriptionVarilux:{
                    ...state.prescriptionVarilux,
                    values:{
                         ...(action.payload === PrescriptionProductTypeVarilux.NONE ? 
                                initialState.prescriptionVarilux.values : 
                                state.prescriptionVarilux.values)
                    },
                    productSelected:action.payload
                }
            }
        },
        setEyeCondition:(state, action: PayloadAction<PrescriptionEyeConditionType>) => {
            return {
                ...state,
                ametropia:action.payload
            }
        },
        setAddSegmentation:(state, action: PayloadAction<PrescriptionAddSegmentationType>) => {
            return {
                ...state,
                additionSegmentation:action.payload
            }
        },
        setCategoryPercentages:(state, action: PayloadAction<IVariluxResult | null>) => {
            return {
                ...state,
                categoryPercentages:action.payload
            }
        },
        setLensCategorySelected:(state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                lensCategorySelected:action.payload,
            }
        },
        resetData:(state)=>{
            return{
                ...state,
                ...initialState,
            }
        },
        setShowDownloadPopup: (state, action: PayloadAction<boolean>) => {
            return{
                ...state,
                showDownloadPopup:action.payload
            }
        },
        setPrescriptionVideoPlay: (state, action: PayloadAction<boolean>) => {
            return{
                ...state,
                prescriptionVideoPlay:action.payload
            }
        },
}
});

export const getVariluxG9 = (state:iRootState) => state.variluxG9;
export default variluxgG9Slice;